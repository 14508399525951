import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Container, Grid, FormGroup, FormControl, Button, TextareaAutosize } from '@material-ui/core';
import './Customer.scss';
import app from '../../firebase';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";

const AttendanceSubmitted = (props) => {

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const [channelUuid, setChannelUuid] = useState('');
    const [channel, setChannel] = useState('');
    const [feeling, setFeeling] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        console.log(props.location.state);
        if (props.location.state != undefined) {
            let initchannelUuid = props.location.state.channelUuid;
            setChannelUuid(initchannelUuid);
            setChannel(props.location.state.channel);
            setFeeling(props.location.state.feeling);
            setTimeout(function( ){
                setLoading(false);
            }, 1000)
        }
        else {
            setLoading(false);
        }
        
    }, []);

    // submit the anwsers
    const handleSubmit = () => {

        // get the current Canada/Toronto Time formated as dd/mm/yyyy hh:mm:ss
        var canadaTime = new Date().toLocaleString("en-US", {timeZone: "America/Toronto"});
        console.log('Canada Toronto time: '+ (new Date(canadaTime)));
        let dCanadaTime = new Date(canadaTime);
        let ddformatToday = [dCanadaTime.getMonth()+1,
            dCanadaTime.getDate(),
            dCanadaTime.getFullYear()].join('/')+' '+
                [dCanadaTime.getHours(),
                    dCanadaTime.getMinutes(),
                    dCanadaTime.getSeconds()].join(':');
        var submittedDate = ddformatToday;

        console.log(comment);
        if (feeling == 'approve') {
            console.log('approve content is sumbitted');
            app.database().ref('channels/' + channelUuid + '/comments').push({
                approveComment: comment,
                timestamp: submittedDate
            })
            .then(() => {
                console.log('success');
                props.history.push('/id/'+ channelUuid);
            })
            .catch(function(err){console.log(err)});
            
        }
        else if (feeling == 'normal') {
            console.log('normal content is submitted');
            app.database().ref('channels/' + channelUuid + '/comments').push({
                normalComment: comment,
                timestamp: submittedDate
            })
            .then(() => {
                console.log('success');
                props.history.push('/id/'+ channelUuid);
            })
            .catch(function(err){console.log(err)});
        }
        else if (feeling == 'unapprove') {
            console.log('unapprove content is submitted');
            app.database().ref('channels/' + channelUuid + '/comments').push({
                unapproveComment: comment,
                timestamp: submittedDate
            })
            .then(() => {
                console.log('success');
                props.history.push('/id/'+ channelUuid);
            })
            .catch(function(err){console.log(err)});
        }
    }
    // handle change for textarea.
    const handleChange = (e) => {
        setComment(e.target.value);
    }



    // show loading when page render FULLY.
    if( loading == true ) return (
        <Container style={{ display:'flex', height: '90vh'}}>
            <h1 style={{margin: 'auto'}}>Loading...</h1>
        </Container>
    );

    return (
        channelUuid ?
                <Container
                    maxWidth="xs"
                    style={{ marginTop: '80px' }}
                >
                    <Grid
                        container
                        item
                        md={12}
                        xs={12}
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            justify="center"
                            xs={12}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <img src={channel.logo} className="displayName" /><p>{channel.displayName}</p>


                        </Grid>


                        <Grid
                            container
                            item
                            direction="row"
                            justify="center"
                            xs={12}
                        >

                            <h4 style={{marginTop:"15px",marginBottom:"15px"}}>Thank you for complying!</h4>
                        </Grid>

                        <Grid
                            container
                            item
                            direction="row"
                            justify="center"
                            xs={12}
                        >

                            <Alert severity="success">
                                Successfully submitted!
                            </Alert>
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            direction="column"
                            style={{ margin: '20px', textAlign: 'center' }}
                        >
                            <p>You may need to present this screen when requested. Please screen capture this page for your own records.</p>

                        </Grid>

                    </Grid>
                </Container>
            :
            ''
    );
}

export default withRouter(AttendanceSubmitted);