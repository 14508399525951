import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {Button, Container, FormControl, FormGroup, Grid} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {sharedAssets} from "../../assets";
import './Customer.scss';
import app from '../../firebase';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AttendanceTaker = (props) => {

    const [alertOpen, setAlertOpen] = useState(false);
    const [channelUuid, setChannelUuid] = useState('');
    const [loading, setLoading] = useState(false);
    const [channel, setChannel] = useState('');
    const [formData, setFormData] = useState({
        name:'',
        phoneNumber: '',
        tableLocation: '',
        partySize:''
    });
    const [internalUse, setInternalUse] = useState(false)

    const handleChange =(event) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[event.target.name] = event.target.checked;
        setFormData(form_data);
    }
    const handleChangeInput = (event) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[event.target.name] = event.target.value;
        setFormData(form_data);
    }

    const [date, setDate] = useState('');
    useEffect(() => {

        console.log(props.match.params.id);

        let urlID = props.match.params.id;
        if (urlID.includes("INTERNAL_MODE")){
            setInternalUse(true)
        }
        let initchannelUuid = (props.match.params.id).replace("INTERNAL_MODE","");
        setLoading(true);
        app.database().ref('attendanceV1/prompts/' + initchannelUuid).on('value', snapshot => {

            if (snapshot && snapshot.exists())
            {
                console.log("exists")
                let useTimeZone = "America/Toronto";
                if (snapshot.val().channelTimezone){
                    useTimeZone = snapshot.val().channelTimezone
                }
                // get the current Canada/Toronto Time formated as dd/mm/yyyy hh:mm:ss
                var canadaTime = new Date().toLocaleString("en-US", {timeZone: useTimeZone});
                console.log('Canada Toronto time: '+ (new Date(canadaTime)));
                let dCanadaTime = new Date(canadaTime);
                let ddformatToday = [dCanadaTime.getMonth()+1,
                        dCanadaTime.getDate(),
                        dCanadaTime.getFullYear()].join('/')+' '+
                    [dCanadaTime.getHours(),
                        dCanadaTime.getMinutes(),
                        dCanadaTime.getSeconds()].join(':');
                setDate(ddformatToday);

                //console.log(snapshot.val());
                setChannelUuid(initchannelUuid);

                //init channel data
                //first ensure icon is ok
                if (typeof snapshot.val().logo === 'undefined') {

                    alert("nothing")
                    let temp_logo_url = "https://firebasestorage.googleapis.com/v0/b/mpressions-bf.appspot.com/o/images%2F1001002030e0340dff200defaultLogo.png?alt=media&token=a7dc9301-952b-4c8a-8f34-bd541f2b2503"
                    snapshot.val().logo = ""
                    snapshot.val().logoUrl = temp_logo_url
                }
                setChannel(snapshot.val());

              
                setLoading(false);
            }
            else{
                console.log("non existent");
                setChannelUuid('')
                setLoading(false);
            }
        });
    }, []);

    // submit the anwsers
    const handleSubmit = () => {

        app.database().ref('attendanceV1/responses/' + channelUuid + '/log').push({
            name: formData.name,
            number: formData.phoneNumber,
            tableLocation: formData.tableLocation,
            partySize: formData.partySize,
            timeStamp: date,

        });
        if (internalUse === false){
            //Send to Thank You screen
            props.history.push({
                pathname: '/submit/attendanceLogged',
                state: {
                    channelUuid : channelUuid,
                    feeling: 'approve',
                    channel: channel,
                }
            });
        }else{
            setAlertOpen(true)

            setFormData({
                name:'',
                phoneNumber: '',
                tableLocation: '',
                partySize:''
            })
        }

    }
    //alert handling
    const handleClick = () => {
        setAlertOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };


    // show loading when page render FULLY.
    if( loading == true ) return (
                                    <Container style={{ display:'flex', height: '90vh'}}>
                                        <h1 style={{margin: 'auto'}}>Loading...</h1>
                                    </Container>
                                );

    return (
            channelUuid 
            ? 
            <Container
                maxWidth="xs"
                style={{ marginTop: '80px' }}
            >
                <Grid
                    container
                    item
                    md={12}
                    xs={12}
                >
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        xs={12}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >

                        <img src={channel.logo} className="displayName" /><p>{channel.displayName}</p>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        xs={12}
                        style={{textAlign: 'center'}}
                    >
                        <p style={{marginTop:"15px",marginBottom:"15px"}}>{channel.prompt}</p>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        direction="row"
                        style={{ margin: 'auto', textAlign: 'center' }}
                    >
                        <ValidatorForm
                            onSubmit={handleSubmit}
                            style={{margin: 'auto'}}
                        >
                        <FormGroup>
                            <FormControl style={{paddingBottom:"5px"}}>
                                <TextValidator
                                    label="Name"
                                    name="name"
                                    onChange={handleChangeInput}
                                    value={formData.name}
                                    helperText="Please provide a contact name"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormControl style={{paddingBottom:"10px"}}>
                                <TextValidator
                                    label="Phone Number"
                                    name="phoneNumber"
                                    onChange={handleChangeInput}
                                    value={formData.phoneNumber}
                                    helperText="Please provide a phone number. No phone number? Provide an email address"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </FormControl>
                        </FormGroup>
                            {channel.getPartySize ?
                                <FormGroup>
                                <FormControl style={{paddingBottom:"10px"}}>
                                    <TextValidator
                                        label="Size of party"
                                        name="partySize"
                                        onChange={handleChangeInput}
                                        value={formData.partySize}
                                        helperText="What is the size of your party/group?"
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                </FormControl></FormGroup> : <span/>

                            }

                            {channel.getTableLocation ?
                                <FormGroup>
                                <FormControl style={{paddingBottom:"10px"}}>
                                    <TextValidator
                                        label="Location/Section/Table"
                                        name="tableLocation"
                                        onChange={handleChangeInput}
                                        value={formData.tableLocation}
                                        helperText="Where are you sitting or located?"
                                        validators={['required']}
                                        errorMessages={['this field is required, please ask a team member if you have any questions']}
                                    />
                                </FormControl></FormGroup> : <span/>
                            }
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                            >
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px', marginTop:"20px" }} >submit</Button>

                            </Grid>
                        </ValidatorForm>

                    </Grid>
                </Grid>

                <Snackbar open={alertOpen} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Entry Logged
                    </Alert>
                </Snackbar>


                    <p style={{paddingTop:"40px"}} className="customerLegalAgreement">Submissions here may be given to public health authorities in accordance with local laws</p>
                    <p style={{paddingTop:"10px"}} className="customerLegalAgreement">By submitting a response you agree to goodBii's&nbsp;</p>
                    <p className="customerLegalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{props.history.push('/terms')}}>Terms of Use&nbsp;</a></p>
                    <p className="customerLegalAgreement">and&nbsp;</p>
                    <p className="customerLegalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{props.history.push('/privacy')}}>Privacy Policy</a></p>
                    <p className="customerLegalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{props.history.push('/privacy')}}>In the United States and Canada, data collected here (excluding bulk performance metrics for quality improvement purposes) will not be used in any other way that does not relate to business contact tracing/monitoring the flow of people entering a given premises. goodBii solely collects data here on behalf of a client, therefore, please ensure you trust the establishment you are providing data to and ask about their data policy if you have concerns. Data collected here can be accessed by local authorities, goodBii, and this establishment plus any partners. In most juristictions, this data will be retained for no more than 30 days before it is deleted.</a></p>
                    <p style={{paddingTop:"10px"}} className="customerLegalAgreement">goodBii is a Canadian company with data centers in Canada and the United States</p>

            </Container>

            :
            <Container
                maxWidth="xs"
                style={{display: 'flex', height: '90vh'}}
            >
                <h1 style={{ margin: 'auto', textAlign: 'center' }}>
                    Page Not Found.
                    <br/>
                    404
                </h1>
            </Container>
    );
}

export default withRouter(AttendanceTaker);