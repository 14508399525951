import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Container, Grid, FormGroup, FormControl, FormControlLabel, Checkbox, TextareaAutosize, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import app from '../firebase';

const EditChannel = (props) => {

    const [formData, setFormData] = useState({
        question:'',
        internalName: '',
        checkedA: false
    });

    const [userID, setUserID] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        console.log(props.location.state);
        setUserID(props.location.state.userId);
        setDisplayName(props.location.state.displayName);
        setLogoUrl(props.location.state.logoUrl);
        setFormData({
            question: props.location.state.channel.question,
            internalName: props.location.state.channel.internalName,
            checkedA: props.location.state.channel.freeformCheck
        });
    }, []);

    const handleChange =(event) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[event.target.name] = event.target.checked;
        setFormData(form_data);
    }
    const handleChangeInput = (event) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[event.target.name] = event.target.value;
        setFormData(form_data);
    }
    const handleSubmit = () => {
        console.log(formData.question,formData.internalName, formData.checkedA);
        let channelUuid = props.location.state.channel.uuid;

        // get the current Canada/Toronto Time formated as dd/mm/yyyy hh:mm:ss
        var canadaTime = new Date().toLocaleString("en-US", {timeZone: "America/Toronto"});
        console.log('Canada Toronto time: '+ (new Date(canadaTime)));
        let dCanadaTime = new Date(canadaTime);
        let ddformatToday = [dCanadaTime.getMonth()+1,
            dCanadaTime.getDate(),
            dCanadaTime.getFullYear()].join('/')+' '+
                [dCanadaTime.getHours(),
                    dCanadaTime.getMinutes(),
                    dCanadaTime.getSeconds()].join(':');
        let dateEdit = ddformatToday;

        app.database().ref('channels/' + channelUuid).update({
             question: formData.question,
             internalName: formData.internalName,
             freeformCheck: formData.checkedA,
             displayName: displayName,
             logo: logoUrl,
             registerDate: dateEdit,
             uuid: channelUuid
        })
        .then( () => {
            console.log(`Success Editing ${props.location.state.channel.uuid} Channel`);
            props.history.push('/dashboard');
        } )
        .catch(function(err) { console.error(err) });
    }
    const handleCancel = () => {
        console.log('go back for cancel');
        props.history.push('/dashboard');
    }

    return (
        <Container 
            maxWidth="xs"
            style={{display: 'flex', height: '90vh'}}
        >
            <ValidatorForm
                onSubmit={handleSubmit}
                style={{margin: 'auto'}}
            >
                <Grid
                    container
                    item
                    md={12}
                    spacing={1}
                    direction="column"
                    justify="flex-start"
                >
                        <FormGroup>
                            <FormControl style={{paddingBottom:"5px"}}>
                                <TextValidator 
                                    label="What do you want to ask?"
                                    name="question"
                                    onChange={handleChangeInput}
                                    value={formData.question}
                                    helperText="We suggest a Yes/No type question"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormControl style={{paddingBottom:"10px"}}>
                                <TextValidator 
                                    label="Type a internal name"
                                    name="internalName"
                                    onChange={handleChangeInput}
                                    value={formData.internalName}
                                    helperText="Internal name, only you can see this (optional)"
                                    validators={[]}
                                    errorMessages={['this field is required']}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormControl style={{paddingBottom:"5px"}}>
                                <FormControlLabel 
                                    control={<Checkbox checked={formData.checkedA} onChange={handleChange} name="checkedA" />}
                                    label="Collect freeform answers after initial response"
                                />
                            </FormControl>
                        </FormGroup>
                    {/*
                        <FormGroup>
                            <FormControl>
                                <TextareaAutosize 
                                    rowsMin={10}
                                    style={{resize: 'none', fontSize: 'larger'}}
                                    name="context"
                                />
                            </FormControl>
                        </FormGroup>

                    */}
                        <Grid
                            container
                            item
                            md={12}
                            direction="row"
                            spacing={1}
                        >
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                            >
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }} >Done</Button>
                            </Grid>
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                            >
                                <Button color="default" variant="contained" style={{ width: '100%', height: '45px' }} onClick={handleCancel}>Cancel</Button>
                            </Grid>       
                        </Grid>
                </Grid>
            </ValidatorForm>
        </Container>
    );
}

export default withRouter(EditChannel);