import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Container, Grid } from '@material-ui/core';
import {sharedAssets} from "../../assets";
import './Customer.scss';
import app from '../../firebase';

const Customer = (props) => {

    const [channelUuid, setChannelUuid] = useState('');
    const [loading, setLoading] = useState(false);
    const [channel, setChannel] = useState('');
    const [approveCount, setApproveCount] = useState(0);
    const [normalCount, setNormalCount] = useState(0);
    const [unapproveCount, setUnapproveCount] = useState(0);

    const [timeBlockUp, setTimeBlockUp] = useState(0)
    const [timeBlockMid, setTimeBlockMid] = useState(0)
    const [timeBlockDown, setTimeBlockDown] = useState(0)

    const [date, setDate] = useState('');
    useEffect(() => {

        console.log(props.match.params.id);
        let initchannelUuid = props.match.params.id;
        setLoading(true);
        app.database().ref('channels/' + initchannelUuid).on('value', snapshot => {

            if (snapshot && snapshot.exists())
            {
                let useTimeZone = "America/Toronto";
                if (snapshot.val().channelTimezone){
                    useTimeZone = snapshot.val().channelTimezone
                }
                // get the current Canada/Toronto Time formated as dd/mm/yyyy hh:mm:ss
                var canadaTime = new Date().toLocaleString("en-US", {timeZone: useTimeZone});
                console.log('Canada Toronto time: '+ (new Date(canadaTime)));
                let dCanadaTime = new Date(canadaTime);
                let ddformatToday = [dCanadaTime.getMonth()+1,
                        dCanadaTime.getDate(),
                        dCanadaTime.getFullYear()].join('-')+'-'+
                    [dCanadaTime.getHours()];
                setDate(ddformatToday);

                //console.log(snapshot.val());
                setChannelUuid(initchannelUuid);

                //init channel data
                //first ensure icon is ok
                if (typeof snapshot.val().logo === 'undefined') {

                    alert("nothing")
                    let temp_logo_url = "https://firebasestorage.googleapis.com/v0/b/mpressions-bf.appspot.com/o/images%2F1001002030e0340dff200defaultLogo.png?alt=media&token=a7dc9301-952b-4c8a-8f34-bd541f2b2503"
                    snapshot.val().logo = ""
                    snapshot.val().logoUrl = temp_logo_url
                }
                setChannel(snapshot.val());
                //init approve count
                if ( snapshot.val().responses && snapshot.val().responses.approve ) {
                    setApproveCount(snapshot.val().responses.approve);
                }
                if ( snapshot.val().responses && snapshot.val().responses.normal ) {
                    setNormalCount(snapshot.val().responses.normal);
                }
                if ( snapshot.val().responses && snapshot.val().responses.unapprove ) {
                    setUnapproveCount(snapshot.val().responses.unapprove);
                }
                //get timeblock values
                if ( snapshot.val().responses && snapshot.val()["responses"]["timeBlocks"] ) {
                    if (snapshot.val()["responses"]["timeBlocks"][ddformatToday]) {
                        if (snapshot.val()["responses"]["timeBlocks"][ddformatToday]["up"]) {
                            setTimeBlockUp(snapshot.val()["responses"]["timeBlocks"][ddformatToday]["up"])
                        }
                        if (snapshot.val()["responses"]["timeBlocks"][ddformatToday]["mid"]) {
                            setTimeBlockMid(snapshot.val()["responses"]["timeBlocks"][ddformatToday]["mid"])
                        }
                        if (snapshot.val()["responses"]["timeBlocks"][ddformatToday]["down"]) {
                            setTimeBlockDown(snapshot.val()["responses"]["timeBlocks"][ddformatToday]["down"])
                        }

                    }else{
                        console.log("no timed blocks for " + ddformatToday)
                    }
                }else{
                    console.log("no timed blocks area")
                }
              
                setLoading(false);
            }
            else{
                console.log("non existent");
                setChannelUuid('')
                setLoading(false);
            }
        });
    }, []);

    // submit the anwsers
    const handleClick = (index) => {
        console.log(index);


        if (index == 1) {
            
            // push the timedReponses in database/channles/uuid/response
            /*
            app.database().ref('channels/' + channelUuid + '/responses/timedResponses').push({
                value: 'approve',
                timeStamp: dateToday
            });
            */

            // update timeBlocks count in databse/channels/uuid/responses/timeBlocks
            app.database().ref('channels/' + channelUuid + '/responses/timeBlocks/' + date).update({
                up: (timeBlockUp + 1)
            }).catch(function (err) { console.error(err) });

            // update approve count in databse/channels/uuid/responses
            app.database().ref('channels/' + channelUuid + '/responses').update({
                approve: (approveCount + 1)
            })
            .then(() => {
                console.log('success Approve update');
                if ( channel.freeformCheck == true ) {
                    props.history.push({
                        pathname: '/submit/comment',
                        state: {
                            channelUuid : channelUuid,
                            feeling: 'approve',
                            channel: channel,
                        }
                    });
                }
                else {
                    //Send to Thank You screen
                    props.history.push({
                        pathname: '/submit/thankYou',
                        state: {
                            channelUuid : channelUuid,
                            feeling: 'approve',
                            channel: channel,
                        }
                    });
                }
            })
            .catch(function (err) { console.error(err) });
        } else if(index == 2) {
            // update timeBlocks count in databse/channels/uuid/responses/timeBlocks
            app.database().ref('channels/' + channelUuid + '/responses/timeBlocks/' + date).update({
                mid: (timeBlockMid + 1)
            }).catch(function (err) { console.error(err) });
            // update normal count in databse/channels/uuid/responses
            app.database().ref('channels/' + channelUuid + '/responses').update({
                normal: (normalCount + 1)
            })
            .then(() => {
                console.log('success Normal update');
                if ( channel.freeformCheck == true ) {
                    props.history.push({
                        pathname: '/submit/comment',
                        state: {
                            channelUuid : channelUuid,
                            feeling: 'normal',
                            channel: channel,
                        }
                    });
                }
                else {
                    props.history.push({
                        pathname: '/submit/thankYou',
                        state: {
                            channelUuid : channelUuid,
                            feeling: 'normal',
                            channel: channel,
                        }
                    });
                }
            })
            .catch(function (err) { console.error(err) });
        } else if(index == 3) {
            // update timeBlocks count in databse/channels/uuid/responses/timeBlocks
            app.database().ref('channels/' + channelUuid + '/responses/timeBlocks/' + date).update({
                down: (timeBlockDown + 1)
            }).catch(function (err) { console.error(err) });
            // update unapprove count in databse/channels/uuid/responses
            app.database().ref('channels/' + channelUuid + '/responses').update({
                unapprove: (unapproveCount + 1)
            })
            .then(() => {
                console.log('success Unapprove update');
                if ( channel.freeformCheck == true ) {
                    props.history.push({
                        pathname: '/submit/comment',
                        state: {
                            channelUuid : channelUuid,
                            feeling: 'unapprove',
                            channel: channel,
                        }
                    });
                }
                else {
                    props.history.push({
                        pathname: '/submit/thankYou',
                        state: {
                            channelUuid : channelUuid,
                            feeling: 'unapprove',
                            channel: channel,
                        }
                    });
                }
            })
            .catch(function (err) { console.error(err) });
        }

    }

    // show loading when page render FULLY.
    if( loading == true ) return (
                                    <Container style={{ display:'flex', height: '90vh'}}>
                                        <h1 style={{margin: 'auto'}}>Loading...</h1>
                                    </Container>
                                );

    return (
            channelUuid 
            ? 
            <Container
                maxWidth="xs"
                style={{ marginTop: '80px' }}
            >
                <Grid
                    container
                    item
                    md={12}
                    xs={12}
                >
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        xs={12}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >

                        <img src={channel.logo} className="displayName" /><p>{channel.displayName}</p>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        xs={12}
                        style={{textAlign: 'center'}}
                    >
                        <p style={{marginTop:"15px",marginBottom:"15px"}}>{channel.question}</p>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        direction="row"
                        style={{ margin: 'auto', textAlign: 'center' }}
                    >
                        <Grid
                            item
                            xs={4}
                        >
                            <img style={{width:"75px",height:"75px",cursor:"pointer"}} onClick={() => handleClick(1)} src={sharedAssets.linkThumbUp} />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <img style={{width:"75px",height:"75px",cursor:"pointer"}} onClick={() => handleClick(2)} src={sharedAssets.linkThumbNeutral} />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <img style={{width:"75px",height:"75px",cursor:"pointer"}} onClick={() => handleClick(3)} src={sharedAssets.linkThumbDown} />
                        </Grid>
                    </Grid>
                </Grid>

                    <p style={{paddingTop:"40px"}} className="customerLegalAgreement">By submitting a response you agree to our&nbsp;</p>
                    <p className="customerLegalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{props.history.push('/terms')}}>Terms of Use&nbsp;</a></p>
                    <p className="customerLegalAgreement">and&nbsp;</p>
                    <p className="customerLegalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{props.history.push('/privacy')}}>Privacy Policy</a></p>


            </Container>

            :
            <Container
                maxWidth="xs"
                style={{display: 'flex', height: '90vh'}}
            >
                <h1 style={{ margin: 'auto', textAlign: 'center' }}>
                    Page Not Found.
                    <br/>
                    404
                </h1>
            </Container>
    );
}

export default withRouter(Customer);