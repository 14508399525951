import React, { useCallback, useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../../firebase";
import { AuthContext } from "../auth/Auth";
import {sharedAssets} from "../../assets";

import {
    Button,
    Grid,
    Container,
    FormControl,
    FormGroup,
    FormHelperText,
    Tabs,
    Tab,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Alert from '@material-ui/lab/Alert';
import '../auth/style/Login.scss';
import './styles.css'

import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
//Images
import oneStar from './article_images/onestar.png'
import newTakeout from './article_images/newTakeoutService.png'
import posterExamples from './article_images/locations.png'

//graphing
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Brush,} from 'recharts';
import ResponsiveContainer from "recharts/es6/component/ResponsiveContainer";
import MetaTags from "react-meta-tags";
const CustomizedDot = (props) => {
    const {
        cx, cy, stroke, payload, value,
    } = props;

    if (value > 0) {
        return (
            <svg x={cx - 8} y={cy - 8} width={16} height={16} fill="green" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
            </svg>
        );
    }

    else if (value < 0) {
        return (
            <svg x={cx - 8} y={cy - 8} width={16} height={16} fill="red" viewBox="0 0 1024 1024">
                <path
                    d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z"/>
            </svg>
        );
    }
    else{
        return (
            <svg x={cx - 1} y={cy - 1} width={2} height={2} fill="gray" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
            </svg>
        );
    }
};

const data = [
    {name:'3pm', 'past period':-0.67, opinion: -0.7, responses: 5},
    {name:'4pm', 'past period':-0.63, opinion: -0.4, responses: 11},
    {name:'5pm', 'past period':-0.14, opinion: -0.19, responses: 7},
    {name:'6pm', 'past period':0.22, opinion: 0.23, responses:24},
    {name:'7pm', 'past period':0.45, opinion: 0.34, responses: 40},
    {name:'8pm', 'past period':0.9, opinion: 0.95, responses: 30},
    {name:'9pm', 'past period':0.61, opinion: 0.55, responses: 21},
    {name:'10pm', 'past period':0.11, opinion: -0.16, responses: 14},
    {name:'11pm', 'past period':-0.04, opinion: 0.12, responses: 6},
]



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        maxWidth: 400,
        margin: 'auto',
    },
}));

const useStylesForAlert = makeStyles((theme) => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop:theme.spacing(2),
            }
        },
    })
);


const RestaurantSignup = ({ history }) => {
    const classes = useStyles();
    const classesForAlert = useStylesForAlert();
    const [value, setValue] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        hospital: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [foo, setFoo] = useState(false);

    const tabHandleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== formData.password) {
                return false;
            }
            return true
        });

        let search = window.location.search;
        let params = new URLSearchParams(search);
        setFoo(params.get('confirmed'));

    }, [formData]);

    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await app
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
                history.push("/dashboard");
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );

    const handleSignUp = useCallback(async event => {
        setSubmitted(true);
        event.preventDefault();
        const { email, password, phone, name, hospital } = event.target.elements;

        console.log(email.value, password.value, phone.value, name.value, hospital.value);
        try {
            // create new user account with email and password
            await app
                .auth()
                .createUserWithEmailAndPassword(email.value, password.value)
                .then((authData) => {
                    console.log(authData.user.uid);
                    // this is data to save in the firebase
                    let todayDate = new Date().toDateString();
                    app.database().ref('users/' + authData.user.uid).set({
                        username : name.value,
                        email: email.value,
                        hospital: hospital.value,
                        phone: phone.value,
                        registered: todayDate,
                        plan: 'EBB',
                        planStart: todayDate,
                        monthlyUsage: 0
                    })
                        .then((data) => {
                            // redirect to send email verify page
                            window.location ='/emailverify';
                        }).catch(function(error) {
                        console.log(error);
                    });
                }).catch(function(error) {
                    console.log(error);
                });


        } catch (error) {
            alert(error);
        }
        setSubmitted(false);
    }, [history]);

    // const { currentUser } = useContext(AuthContext);
    // if (currentUser) {
    //     return <Redirect to="/" />;
    // }

    const handleChange = (e) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[e.target.name] = e.target.value;
        setFormData(form_data);
    }


    return (
        <div>
            <MetaTags>
                <title>goodBii for Restaurants</title>
                <meta id="meta-description" name="description" content="Customer feedback for your restaurant" />
                <meta id="og-title" property="og:title" content="goodBii for Restaurants" />
            </MetaTags>

            <u onClick={()=>window.location ='/'}>
                <div style={{display:"block"}}>{sharedAssets.logoSmaller}</div>
            </u>
            <h1 style={{display:"flex", justifyContent:"center",marginLeft:"10%",marginRight:"10%"}}>
                Feedback Collection for Restaurants and Bars
            </h1>

            <CardGroup style={{marginLeft:"10%",marginRight:"10%", marginTop:"40px"}}>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <img src={newTakeout} style={{width:"100%"}}/>
                </Card.Body></Card>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <h2 style={{ display:'flex', justifyContent:'center' }}>Create simple to answer questions for your customers</h2>
                    <p style={{ display:'flex', justifyContent:'center' }}>The best questions can be answered with a thumbs up or down</p>
                </Card.Body></Card>
            </CardGroup>
            <CardGroup style={{marginLeft:"10%",marginRight:"10%", marginTop:"40px"}}>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <h2 style={{ display:'flex', justifyContent:'center' }}>Ask customers for feedback</h2>
                    <p style={{ display:'flex', justifyContent:'center' }}>Make a poster, slap it on a takeout box, or place it on the table. We have native QR code generation and direct link sharing. Need ideas? Our team is here to help!</p>

                </Card.Body></Card>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <img src={posterExamples} style={{width:"100%"}}/>
                </Card.Body></Card>
            </CardGroup>
            <CardGroup style={{marginLeft:"10%",marginRight:"10%", marginTop:"40px"}}>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <img src={oneStar} style={{width:"100%"}}/>
                </Card.Body></Card>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <h2 style={{ display:'flex', justifyContent:'center' }}>Get the negative feedback before they hit the reviews</h2>
                    <p style={{ display:'flex', justifyContent:'center' }}>Know what's wrong before it becomes bad PR.</p>
                </Card.Body></Card>
            </CardGroup>

            <CardGroup style={{marginLeft:"10%",marginRight:"10%", marginTop:"40px"}}>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <h2 style={{ display:'flex', justifyContent:'center' }}>See the trends. Make changes</h2>
                    <p style={{ display:'flex', justifyContent:'center' }}>Does your establishment do better at certain times? Zero in on times with poor performance
                    and make changes. Have hours where you do really well? Find the connection!</p>

                    <p style={{ display:'flex', justifyContent:'center', marginTop:"30px" }}>It is well established that customers are more likely to provide feedback if they had an extremely positive
                    or extremely negative experience. Timed-averages can help you filter out these outliers and see the big picture.</p>
                </Card.Body></Card>
                <Card style={{borderStyle: "solid", borderColor: "#ffffff"}}><Card.Body>
                    <h4 style={{display:"flex", justifyContent:"center", marginBottom:"15px"}}>How was the entertainment?</h4>
                    <div style={{display:"flex", justifyContent:"center", marginTop:"15px", marginBottom:"30px"}}>

                        <LineChart data={data} syncId="set1" width={400} height={200}
                                   margin={{top: 10, right: 65, left: 0, bottom: 10}}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis domain={[-1,1]}/>
                            <Tooltip/>

                            <Line type="monotone" dataKey="past period" stroke="#85827E" />
                            <Line type="monotone" dataKey="opinion" stroke="#311CEB" dot={<CustomizedDot />}/>
                        </LineChart>

                    </div>
                    <div style={{display:"flex", justifyContent:"center", marginTop:"30px", marginBottom:"30px"}}>

                        <AreaChart
                            data={data}
                            width={400} height={75}
                            syncId="set1"
                            margin={{top: 10, right: 65, left: 0, bottom: 10}}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />

                            <Area type="monotone" dataKey="responses" stroke="#ffc800" fill="#ffc600" />
                        </AreaChart>

                    </div>
                    <footer style={{ display:'flex', justifyContent:'center' }} className="blockquote-footer">
                         -<cite title="Source Title">invest in better entertainment at opening?</cite>
                    </footer>
                </Card.Body></Card>
            </CardGroup>

        <Container maxWidth="lg" style={{backgroundColor:"#fcb040", paddingTop:"30px", paddingBottom:"30px"}}>
            <h2 style={{color:"#ffffff", display:"flex", justifyContent:"center"}}>Try it out today, free</h2>
            <Grid
                container
                spacing={1}
                item
                xs={12}
                direction="row"
                justify="center"
            >
                <div className={classesForAlert.root}>
                    {foo ? <Alert severity="success" style={{margin: '15px'}}>Account Confirmed, Please Login</Alert> : ''}
                </div>

                <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    direction="row"
                    justify="center"
                    style={{ margin: 'auto', height:"auto" }}
                    id="auth_panel"
                >


                    <div className={classes.root}>
                        <AppBar position="static">
                            <Tabs value={value} onChange={tabHandleChange} aria-label="Auth tabs" TabIndicatorProps={{style: {background:'#ffc600'}}}>
                                <Tab label="Login" {...a11yProps(0)} style={{ width: '50%' }} />
                                <Tab label="Sign Up" {...a11yProps(1)} style={{ width: '50%' }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <ValidatorForm
                                onSubmit={handleLogin}
                                useRef="form"
                            >
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Email"
                                            name="email"
                                            onChange={handleChange}
                                            value={formData.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['this field is required', 'email is not valid']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Password"
                                            name="password"
                                            type="password"
                                            onChange={handleChange}
                                            value={formData.password}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <br />
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }}>
                                    Login
                                </Button>
                            </ValidatorForm>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ValidatorForm
                                onSubmit={handleSignUp}
                                useRef="form"
                            >
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Name"
                                            name="name"
                                            aria-describedby="name-helper-text"
                                            onChange={handleChange}
                                            value={formData.name}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                        <FormHelperText id="name-helper-text">Your name</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Name of Restaurant"
                                            name="hospital"
                                            onChange={handleChange}
                                            value={formData.hospital}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Email"
                                            name="email"
                                            onChange={handleChange}
                                            aria-describedby="email-helper-text"
                                            value={formData.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['this field is required', 'email is not valid']}
                                        />
                                        <FormHelperText id="email-helper-text">You will use this email to login</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Phone Number"
                                            name="phone"
                                            onChange={handleChange}
                                            value={formData.phone}
                                            validators={['required', 'isNumber']}
                                            errorMessages={['this field is required', 'phone number is not valid']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Password"
                                            name="password"
                                            type="password"
                                            onChange={handleChange}
                                            value={formData.password}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Confirm password"
                                            name="repeatPassword"
                                            type="password"
                                            validators={['required', 'isPasswordMatch']}
                                            errorMessages={[ 'this field is required', 'password mismatched' ]}
                                            onChange={handleChange}
                                            value={formData.repeatPassword}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <br />

                                <span>
                                    <p className="legalAgreement">By continuing you agree to our&nbsp;</p>
                                    <p className="legalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{history.push('/terms')}}>Terms of Use&nbsp;</a></p>
                                    <p className="legalAgreement">and&nbsp;</p>
                                    <p className="legalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{history.push('/privacy')}}>Privacy Policy</a></p>
                                </span>
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }}>
                                    Continue
                                </Button>
                            </ValidatorForm>
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>



        </Container>

        </div>



    );
};

export default withRouter(RestaurantSignup);