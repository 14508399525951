import React from "react";
import { withRouter } from 'react-router';
import './Footer.css'

const Footer = (props) => {

    return(
        <div style={{paddingBottom:"40px",paddingTop:"50px", marginTop:"100px",backgroundColor:"#5273c7"}}>
        <div className="footer-flex">
            <p className="footer-menu-item"><a onClick={()=>{props.history.push('/login')}}>Login</a></p>
            <p className="footer-menu-item"><a onClick={()=>{window.open('https://www.1mpressions.com/goodbii-support')}}>Support</a></p>
            <p className="footer-menu-item"><a onClick={()=>{props.history.push('/terms')}}>Terms of Use</a></p>
            <p className="footer-menu-item"><a onClick={()=>{props.history.push('/privacy')}}>Privacy Policy</a></p>
        </div>
        <p className="copyright-footer"><a style={{cursor:"pointer"}} onClick={()=>{window.open('https://www.1mpressions.com/')}}>copyright 2020 goodBii, an 1mpressions company</a></p>
        </div>
    )
}
export default withRouter(Footer)