import React, { useEffect, Fragment } from "react";
import MetaTags from 'react-meta-tags';

import AOS from "aos";
import $ from "jquery";

import Header from "./components/Header";
import RootHome from "./components/RootHome";
import About from "./components/About";
import HowItWorks from "./components/HowItWorks";
import Footer from "./components/Footer";

import "aos/dist/aos.css";
import "./assets/styles/main.scss";


const Homepage = () => {
    useEffect(() => {
        AOS.init({ once: true });

        let navElement = $("nav");

        $(function() {
            $(window).scrollTop() > navElement.innerHeight()
                ? navElement.addClass("sticky")
                : navElement.removeClass("sticky");
        });
        $(window).on("scroll", function() {
            $(window).scrollTop() > navElement.innerHeight()
                ? navElement.addClass("sticky")
                : navElement.removeClass("sticky");
        });
    });

    return (

        <Fragment>

            <MetaTags>
                <title>goodBii</title>
                <meta id="meta-description" name="description" content="Get feedback for your business with QR codes" />
                <meta id="og-title" property="og:title" content="goodBii home" />
            </MetaTags>

            <Header />
            <main>
                <RootHome />
                <About />
                <HowItWorks />
            </main>

        </Fragment>



    );
};

export default Homepage;
