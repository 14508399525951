import React, { useEffect, useState } from 'react';
import { withRouter, Redirect} from 'react-router';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { QRCode } from 'react-qrcode-logo';


import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import { BlockPicker } from 'react-color';

import './QR_Create.css'
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import MetaTags from "react-meta-tags";

import banner from './feedbackBanner.png'

import defaultLogo from "../../assets/img/logoDefault.png"

import squareIcon from "./squareQR.png"
import dotIcon from "./dotQR.png"

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);


const QRCreate = (props, {history})=>{
    const [internalUseMode,setInternalUseMode] = useState(false)

    useEffect(() => {
        if (props.presets){
            //set up for goodBii users
            console.log(props.presets)
            setInputString(props.presets.link)
            setLogoSrc(props.presets.logoLink)
            setInternalUseMode(true)

            window.scrollTo(0, 0)
        }else{
            //set up for anyone on the web
        }
    }, [])
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }));

    const [codeStyle,setCodeStyle] = useState("squares")

    const [logoWHratio, setLogoWHratio] = useState(1)
    const [logoSizeScaler, setLogoScaler] = useState(0.75)

    const [logo, setLogo] = useState(false)
    const [logoSrc, setLogoSrc] = useState('')
    const [inputString, setInputString] = useState('google.com');
    const [fgColor, setFG] = useState(
        {
            hex: '#000000',
            rgb: {
                r: 51,
                g: 51,
                b: 51,
                a: 1,
            },
            hsl: {
                h: 0,
                s: 0,
                l: .20,
                a: 1,
            }
        }
    );
    const [bgColor, setBG] = useState({
        hex: '#FFFFFF',
        rgb: {
            r: 51,
            g: 51,
            b: 51,
            a: 1,
        },
        hsl: {
            h: 0,
            s: 0,
            l: .20,
            a: 1,
        }
    });
    //
    const [expanded, setExpanded] = useState('panel1');

    const handleAccordianChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChange = (event) => {
        setInputString(event.target.value)
    };
    const handleLogoSrcChange = (event) => {
        setLogoSrc(event.target.value)
    };
    const handleFGChangeComplete = (color, event) => {
        setFG(color)
    };
    const handleBGChangeComplete = (color, event) => {
        setBG(color)
    };

    const [qrSize, setQRSize] = useState(250)
    const [qrMargin, setQRMargin] = useState(20)
    function valuetextSlider(value) {
        return `${value}px`;
    }
    const handleSizeChange = (event, newValue) =>{
        setQRSize(newValue)
    }
    const handleMarginSizeChange = (event, newValue) =>{
        setQRMargin(newValue)
    }
    const handleLogoSizeChange = (event, newValue) =>{
        setLogoScaler(newValue)
    }

    const generatedQR = ()=>{
        let foo = []
        let codePreview = (
            <div style={{display:"flex", justifyContent:"center"}}>
                <QRCode

                    value={inputString}
                    size={qrSize}
                    quietZone={qrMargin}
                    qrStyle={codeStyle}
                    ecLevel={'H'}
                    bgColor={bgColor.hex}
                    fgColor={fgColor.hex}
                    enableCORS={true}

                /></div>)
        if (logo === true){
            //calculate logo Size
            let totalArea = qrSize * qrSize;
            let maxArea = 0.175 * totalArea;

            let logoHeight = Math.sqrt(maxArea/logoWHratio) * logoWHratio * logoSizeScaler;
            let logoWidth = Math.sqrt(maxArea/logoWHratio) * logoSizeScaler;

            codePreview = (
                <div style={{display:"flex", justifyContent:"center"}}>
                <QRCode

                    value={inputString}
                    size={qrSize}
                    ecLevel={'H'}
                    quietZone={qrMargin}
                    qrStyle={codeStyle}
                    bgColor={bgColor.hex}
                    fgColor={fgColor.hex}
                    enableCORS={true}
                    logoImage={changedTempLogo}
                    logoHeight={logoHeight}
                    logoWidth={logoWidth}

            />
                </div>)
        }


        if (inputString.length > 0){
            foo.push(
                codePreview
            )
            foo.push(
                <div style={{display:"flex", justifyContent:"center", marginTop:"10px"}}>
                    <Button variant="contained" startIcon={<SaveIcon />} color="primary" onClick={() => downloadQR('react-qrcode-logo')} style={{ marginBottom:"30px"}}>download as PNG</Button>

                </div>
            )
            foo.push(
                <div style={{display:"flex", justifyContent:"center", marginLeft:"20px", marginRight:"20px"}}>
                    <p className="legalAgreement">We HIGHLY recommend trying your code before downloading</p>
                </div>
            )

        }
        return(
            <div>
                {foo}
            </div>

        )
    }
    const downloadQR = (idParam) => {
        console.log(idParam);
        const canvas = document.getElementById(idParam);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const hiddenFileInput = React.useRef(null);
    const handleUploaderButtonClick = event => {
        hiddenFileInput.current.click();
    };

    const [logoPic, setLogoPic] = useState('');
    const [changedTempLogo, setChangedTempLogo] = useState(defaultLogo);
    const addLogoToCode = () =>{
        setLogo(!logo)
    }
    // detect file input tag value changing or not.
    const changeLogoPic = (e) => {
        console.log(e.target.files[0]);
        const image = e.target.files[0];
        setLogoPic(image);
        const [file] = e.target.files;
        // if (file) document.getElementById('logoPic').src = window.URL.createObjectURL(file);
        if (file) setChangedTempLogo(window.URL.createObjectURL(file));


        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            console.log(img.height);
            console.log(img.width);
            let ratio = img.height/img.width
            setLogoWHratio(ratio)
        }

    }

    //Style
    const changeStyleTo = (style)=>{
        setCodeStyle(style)
    }
    const styleSelectorColor = (style)=>{
        if (style === codeStyle){
            return("primary")
        }else{return("light")}

    }
    //const legal disclaimers
    const legalDisclaimers = () =>{
        if (internalUseMode === false){
            return(
                <Accordion square expanded={expanded === 'panel5'} onChange={handleAccordianChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <Typography>..more about this tool and some stuff you should read</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor:"#e8e8e8"}}>
                        <Typography>
                            <h1>The free and simple QR code creator</h1>
                            <p>Generate static QR codes on the fly, without that middle redirect step. No account creation needed: just paste and go!</p>

                            <Typography>
                                <p className="legalAgreement">And now a few words from our legal department: goodBii does not provide any warranty for this service.</p>
                                <br/>
                                <span>
                                    <p className="legalAgreement">By using this service you agree to our&nbsp;</p>
                                    <p className="legalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{window.open('https://www.goodBii.com/terms')}}>Terms of Use&nbsp;</a></p>
                                    <p className="legalAgreement">and&nbsp;</p>
                                    <p className="legalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{window.open('https://www.goodBii.com/privacy')}}>Privacy Policy</a></p>
                            </span>
                            </Typography>
                            <Typography>
                                copyright 2020 goodBii, an <a style={{cursor:"pointer"}} onClick={()=>{window.open('https://www.1mpressions.com/')}}>1mpressions company</a>
                            </Typography>

                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )
        }
    }
    //const final segment
    const externalUseOnly = () =>{
        if (internalUseMode === false){
            return(
                <u onClick={()=>{window.open('https://goodBii.com/')}}>
                    <img style={{width:"90%", maxWidth:"500px", marginRight:"auto", marginLeft:"auto",
                        marginTop:"40px", marginBottom:"40px", display: "block", cursor:"pointer"}} src={banner}/>
                </u>
            )
        }
    }

    return(
        <div>
            <MetaTags>
                <title>QR Code Creator</title>
                <meta id="meta-description" name="description" content="The goodBii QR Code Creator" />
                <meta id="og-title" property="og:title" content="QR Code Creator" />
            </MetaTags>


            <div style={{display:"flex",justifyContent:"center", marginTop:"20px"}}>
                <h1>QR Code Creator</h1>
                <p>by goodBii</p>
            </div>

            <div style={{display:"flex", justifyContent:"space-evenly", alignContent:"stretch"}}>
                <TextField id="input-area" label="URL, email, string etc." fullWidth value={inputString} onChange={handleChange}
                style={{margin:"20px"}}/>
            </div>

            <div style={{display:"flex", justifyContent:"center"}}>
                {generatedQR()}
            </div>

            <Accordion square expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Colors</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display:"flex", justifyContent:"space-evenly", backgroundColor:"#e8e8e8"}}>

                        <div style={{marginLeft:"5px",marginRight:"5px"}}>
                            <Typography>
                                Foreground Color
                            </Typography>
                            <div style={{marginTop:"20px"}}>
                                <BlockPicker color={fgColor} onChangeComplete={handleFGChangeComplete}
                                colors={['#000000','#FFFFFF', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#fcdf03', '#ff8a65',
                                    '#ba68c8','#D9E3F0','#fcba03', '#00ba92', '#3d316b', '#e09b69', '#549e75', '#3e68c2', '#dce775', '#3d3d3d', '#ff0022']}/>
                            </div>
                        </div>
                        <div style={{marginLeft:"5px",marginRight:"5px"}}>
                            <Typography>
                                Background Color
                            </Typography>
                            <div style={{marginTop:"20px"}}>
                                <BlockPicker color={bgColor} onChangeComplete={handleBGChangeComplete}
                                             colors={['#000000','#FFFFFF', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#fcdf03', '#ff8a65',
                                                 '#ba68c8','#D9E3F0','#fcba03', '#00ba92', '#3d316b', '#e09b69', '#549e75', '#3e68c2', '#dce775', '#3d3d3d', '#ff0022']}/>
                            </div>
                        </div>

                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleAccordianChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Sizes</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor:"#e8e8e8"}}>
                    <div style={{display:"flex", justifyContent:"center"}}><Typography>
                        QR Code Size
                    </Typography></div>
                    <Slider
                        defaultValue={400}
                        value={qrSize}
                        getAriaValueText={valuetextSlider}
                        aria-labelledby="discrete-size-slider"
                        valueLabelDisplay="auto"
                        step={10}
                        marks
                        min={200}
                        max={800}
                        onChange={handleSizeChange}
                    />

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor:"#e8e8e8"}}>
                    <div style={{display:"flex", justifyContent:"center"}}><Typography>
                        Border Width
                    </Typography></div>
                    <Slider
                        defaultValue={20}
                        value={qrMargin}
                        getAriaValueText={valuetextSlider}
                        aria-labelledby="margin-size-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={200}
                        onChange={handleMarginSizeChange}
                    />



                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel3'} onChange={handleAccordianChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Logo</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor:"#e8e8e8"}}>

                    <Typography>Show logo in QR Code</Typography>
                    <Switch checked={logo} onChange={addLogoToCode} />
                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor:"#e8e8e8"}}>
                    <div>
                        <img id="logoPic" src={changedTempLogo} alt="dashboard logo"></img>
                        <p style={{marginTop: '50px' }}><b>Upload your logo (PNG, JPEG, SVG)</b></p>

                        <form >


                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudUploadIcon />}
                                    onClick={handleUploaderButtonClick}
                                >
                                    Upload
                                </Button>
                                <input
                                    type="file"
                                    name="file"
                                    accept="image/jpg,image/png,image/svg,image/jpeg"
                                    single
                                    ref={hiddenFileInput}
                                    onChange={changeLogoPic}
                                    style={{display:'none'}}
                                ></input>

                        </form>


                    </div>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor:"#e8e8e8"}}>
                    <div style={{display:"flex", justifyContent:"center"}}><Typography>
                        Logo Size
                    </Typography></div>
                    <Slider
                        defaultValue={400}
                        value={logoSizeScaler}
                        aria-labelledby="discrete-logo-size-slider"
                        valueLabelDisplay="auto"
                        step={0.05}
                        marks
                        min={0.1}
                        max={1}
                        onChange={handleLogoSizeChange}
                    />

                </AccordionDetails>
            </Accordion>

            <Accordion square expanded={expanded === 'panel4'} onChange={handleAccordianChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Shape</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display:"flex", justifyContent:"space-evenly", backgroundColor:"#e8e8e8"}}>

                    <Button
                        variant="contained"
                        color={styleSelectorColor("squares")}

                        onClick={()=>changeStyleTo("squares")}
                    >
                        <img style={{width:"100px",height:"100px", margin:"10px"}} src={squareIcon} />
                    </Button>

                    <Button
                        variant="contained"
                        color={styleSelectorColor("dots")}

                        onClick={()=>changeStyleTo("dots")}
                    >
                        <img style={{width:"100px",height:"100px", margin:"10px"}} src={dotIcon} />
                    </Button>

                </AccordionDetails>
            </Accordion>

            {legalDisclaimers()}


            {externalUseOnly()}




        </div>
    )
}
export default withRouter(QRCreate)