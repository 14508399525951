import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {Container, Grid, Switch, FormGroup, FormControlLabel, Button} from '@material-ui/core';
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import {sharedAssets} from "../assets";
import './Response.scss';
import app from '../firebase';

//graphing
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Brush,} from 'recharts';
import ResponsiveContainer from "recharts/es6/component/ResponsiveContainer";
const CustomizedDot = (props) => {
    const {
        cx, cy, stroke, payload, value,
    } = props;

    if (value > 0) {
        return (
            <svg x={cx - 8} y={cy - 8} width={16} height={16} fill="green" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
            </svg>
        );
    }

    else if (value < 0) {
        return (
            <svg x={cx - 8} y={cy - 8} width={16} height={16} fill="red" viewBox="0 0 1024 1024">
                <path
                    d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z"/>
            </svg>
        );
    }
    else{
        return (
            <svg x={cx - 1} y={cy - 1} width={2} height={2} fill="gray" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
            </svg>
        );
    }
};



const Response = (props) => {

    const [channel, setChannel] = useState('');
    const [userID, setUserID] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(false);

    const [period, setPeriod] = useState(12)
    const [trends, setTrends] = useState(true);
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        setLoading(true);
        console.log(props);
        if (props.location.state != undefined) {
            setComments('');
            setChannel(props.location.state.channel);
            setUserID(props.location.state.userId);
            let channelUuid = props.location.state.channel.uuid;
            console.log(channelUuid);
            if (props.location.state.channel.comments) {
                app.database().ref('channels/' + channelUuid + '/comments').once('value', function (snap) {
                    snap.forEach(function (childSnap) {
                        console.log(childSnap.key, childSnap.val());
                        if (childSnap.val()) {
                            setComments(oldArray => [...oldArray, childSnap.val()]);

                        }
                    });
                })
                    .then(() => {
                        console.log('success get comments');

                    })
                    .catch(function (err) {
                        console.error(err);
                        //setLoading(false);
                    });

            } else {
                console.log('comments is empty');
                setComments('');
                //setLoading(false);
            }
            app.database().ref('channels/' + channelUuid).on('value', snapshot => {

                if (snapshot && snapshot.exists()) {
                    let useTimeZone = "America/Toronto";
                    if (snapshot.val().channelTimezone){
                        useTimeZone = snapshot.val().channelTimezone
                    }
                    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    var blockData = []
                    var currentTime = new Date().toLocaleString("en-US", {timeZone: useTimeZone});
                    let refBlock = new Date(currentTime);

                    let range = 336

                    for (let i = 0; i < range; i++){
                        let hourOffset = (60*60*1000) * i
                        var timeBlock = new Date();
                        timeBlock.setTime(refBlock.getTime() - hourOffset);

                        let testBlock = [timeBlock.getMonth()+1,
                                timeBlock.getDate(),
                                timeBlock.getFullYear()].join('-')+'-'+
                            [timeBlock.getHours()];

                        //get timeblock values
                        let ups = 0
                        let downs = 0
                        let mids = 0
                        if ( snapshot.val().responses && snapshot.val()["responses"]["timeBlocks"] ) {
                            if (snapshot.val()["responses"]["timeBlocks"][testBlock]) {
                                if (snapshot.val()["responses"]["timeBlocks"][testBlock]["up"]) {
                                    ups = (snapshot.val()["responses"]["timeBlocks"][testBlock]["up"])
                                }
                                if (snapshot.val()["responses"]["timeBlocks"][testBlock]["mid"]) {
                                    mids = (snapshot.val()["responses"]["timeBlocks"][testBlock]["mid"])
                                }
                                if (snapshot.val()["responses"]["timeBlocks"][testBlock]["down"]) {
                                    downs = (snapshot.val()["responses"]["timeBlocks"][testBlock]["down"])
                                }

                            }
                        }

                        let score = (ups + (downs * -1)) / (ups + downs)
                        if ((ups + downs) === 0){
                            score = 0
                        }
                        score = score.toFixed(2);
                        let label = timeBlock.getHours()
                        if (parseInt(label) < 12){
                            if (parseInt(label) === 0){
                                label = "12am"
                            }else {
                                label = label + "am"
                            }
                        }else if (parseInt(label) === 12){
                            label = "12pm"
                        }
                        else{
                            label = String(parseInt(label)-12) + "pm"
                        }

                        blockData.push({name: label, opinion: score, responses: (ups + downs + mids)})

                    }
                    setGraphData(blockData)
                    setLoading(false);


                }
                else{
                    console.log("non existent");
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    }, []);

    const periodSelection = () =>{
        let buttons = []
        if (period == 12){
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="primary" variant="contained" onClick={() => setPeriod(12)}>12hr</Button>)
        }else{
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="light" variant="contained" onClick={() => setPeriod(12)}>12hr</Button>)
        }
        if (period == 24){
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="primary" variant="contained" onClick={() => setPeriod(24)}>24hr</Button>)
        }else{
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="light" variant="contained" onClick={() => setPeriod(24)}>24hr</Button>)
        }
        if (period == 48){
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="primary" variant="contained" onClick={() => setPeriod(48)}>48hr</Button>)
        }else{
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="light" variant="contained" onClick={() => setPeriod(48)}>48hr</Button>)
        }
        if (period == 168){
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="primary" variant="contained" onClick={() => setPeriod(168)}>Week</Button>)
        }else{
            buttons.push(<Button style={{marginLeft:"15px", marginRight:"15px"}} type="submit" color="light" variant="contained" onClick={() => setPeriod(168)}>Week</Button>)
        }
        return(
            <div style={{margin: 'auto', height: "30px", display:"flex", justifyContent:"center"}}>
                {buttons}
            </div>
        )
    }

    function createDataForPeriod(){
        let data = [];
        for (let i = 0; i < period; i++){
            let block = graphData[i]

            block['past period'] = graphData[i + period].opinion

            data.push(block)

        }
        //Make latest block NOW
        if (data.length > 0){
            data[0].name = "now"
        }
        data = data.reverse()
        return(data)
    }

    const graphSection = () => {


        let data = createDataForPeriod()

        if (trends === true) {
            return (
                <ResponsiveContainer width="100%" height="100%">


                    <LineChart data={data} syncId="set1" width={600} height={400}
                               margin={{top: 10, right: 65, left: 0, bottom: 10}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis domain={[-1,1]}/>
                        <Tooltip/>
                        <Legend />

                        <Line type="monotone" dataKey="past period" stroke="#85827E" />
                        <Line type="monotone" dataKey="opinion" stroke="#311CEB" dot={<CustomizedDot />}/>
                    </LineChart>
                </ResponsiveContainer>


            )
        }
    }
    const volumeGraph =()=>{

        let data = createDataForPeriod()

        if (trends === true) {
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={data}
                        width={600} height={100}
                        syncId="set1"
                        margin={{top: 10, right: 65, left: 0, bottom: 10}}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="responses" stroke="#ffc800" fill="#ffc600" />
                    </AreaChart>
                </ResponsiveContainer>

            )
        }
    }

    // show loading when page render FULLY.
    if (loading == true) return (
        <Container style={{display: 'flex', height: '90vh'}}><h1 style={{margin: 'auto'}}>Loading...</h1></Container>
    );

    return (
        channel ?
            <div style={{marginLeft: "5%", marginRight: "5%", marginTop: "1%", marginBottom: "5%"}}>

                <Link to="/dashboard">
                    <Button style={{marginBottom:"15px", marginTop:"15px"}} type="submit" color="primary" variant="contained" onClick={() => "/dashboard"}>⇦</Button>
                </Link>

                <h1 style={{textAlign: "center"}}>{props.location.state.channel.question}</h1>
                <p style={{textAlign: "center"}}>({props.location.state.channel.internalName})</p>

                <Container
                    maxWidth="xs"
                    style={{height: '20vh', display: 'flex'}}
                >

                    <div style={{margin: 'auto'}}>
                        <Grid
                            container
                            item
                            spacing={1}
                            md={12}
                            direction="row"
                        >
                            <Grid
                                item
                                md={4}
                                direction="column"
                                style={{textAlign: 'center', display: 'grid'}}
                            >
                                {sharedAssets.thumbUp}

                                <span>
                                                {channel.responses ? channel.responses.approve : 0}
                                            </span>

                            </Grid>
                            <Grid
                                item
                                md={4}
                                direction="column"
                                style={{textAlign: 'center', display: 'grid'}}
                            >
                                {sharedAssets.thumbNeutral}

                                <span>
                                                {channel.responses ? channel.responses.normal : 0}
                                            </span>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                direction="column"
                                style={{textAlign: 'center', display: 'grid'}}
                            >
                                {sharedAssets.thumbDown}
                                <span>
                                                {channel.responses ? channel.responses.unapprove : 0}
                                            </span>
                            </Grid>

                        </Grid>
                    </div>
                </Container>

                {periodSelection()}


                <div style={{margin: 'auto', height: "300px"}}>
                    {graphSection()}

                </div>
                <div style={{margin: 'auto', height: "150px"}}>
                    {volumeGraph()}
                </div>

                <CardGroup style={{marginTop:"30px", marginBottom:"30px"}}>
                    <Card style={{borderStyle: "solid", borderColor: "#ffffff", borderRadius: "0px"}}><Card.Body>
                    <Container
                        maxWidth="md"
                        style={{display: 'flex'}}
                    >
                        <div style={{margin: 'auto'}}>

                            {
                                channel.freeformCheck ?
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        md={12}
                                    >
                                        <h3>Freeform Comments</h3>
                                        {
                                            comments ?
                                                comments.map((comment, index) => {
                                                    return (
                                                        <div index={index}>
                                                            {
                                                                comment.approveComment ?
                                                                    <p className="responsePara">{sharedAssets.thumbUp}<span>{comment.approveComment}</span>
                                                                    </p> : ''
                                                            }
                                                            {
                                                                comment.normalComment ?
                                                                    <p className="responsePara">{sharedAssets.thumbNeutral}<span>{comment.normalComment}</span>
                                                                    </p> : ''
                                                            }
                                                            {
                                                                comment.unapproveComment ?
                                                                    <p className="responsePara">{sharedAssets.thumbDown}<span>{comment.unapproveComment}</span>
                                                                    </p> : ''
                                                            }
                                                        </div>
                                                    )
                                                })
                                                :
                                                'No freeform feedback yet'
                                        }
                                    </Grid>
                                    :
                                    ''
                            }

                            <Grid
                                containter
                                item
                                md={12}
                                style={{marginTop: '20px'}}
                            >

                            </Grid>
                        </div>
                    </Container>
                    </Card.Body></Card>
                </CardGroup>


                <Link to="/dashboard">
                    <Button style={{marginBottom:"15px", marginTop:"15px"}} type="submit" color="primary" variant="contained" onClick={() => "/dashboard"}>⇦</Button>
                </Link>

            </div>
            :
            <Container
                maxWidth="xs"
                style={{display: 'flex', height: '90vh'}}
            >
                <h1 style={{margin: 'auto', textAlign: 'center'}}>
                    Page Not Found.
                    <br/>
                    404
                </h1>
            </Container>
    );
}

export default withRouter(Response);