import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Container, Grid, FormGroup, FormControl, Button, TextareaAutosize } from '@material-ui/core';
import './Customer.scss';
import app from '../../firebase';
import { ValidatorForm } from 'react-material-ui-form-validator';

const Comment = (props) => {

    const [channelUuid, setChannelUuid] = useState('');
    const [channel, setChannel] = useState('');
    const [feeling, setFeeling] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    const [submittable, setSubmittable] = useState(false)

    useEffect(() => {
        setLoading(true);
        console.log(props.location.state);
        if (props.location.state != undefined) {
            let initchannelUuid = props.location.state.channelUuid;
            setChannelUuid(initchannelUuid);
            setChannel(props.location.state.channel);
            setFeeling(props.location.state.feeling);
            setTimeout(function( ){
                setLoading(false);
            }, 1000)
        }
        else {
            setLoading(false);
        }

        
    }, []);

    // submit the anwsers
    const handleSubmit = () => {
        let useTimeZone = "America/Toronto";
        if (channel.channelTimezone){
            useTimeZone = channel.channelTimezone
        }
        // get the current Canada/Toronto Time formated as dd/mm/yyyy hh:mm:ss
        var canadaTime = new Date().toLocaleString("en-US", {timeZone: useTimeZone});
        console.log('Canada Toronto time: '+ (new Date(canadaTime)));
        let dCanadaTime = new Date(canadaTime);
        let ddformatToday = [dCanadaTime.getMonth()+1,
            dCanadaTime.getDate(),
            dCanadaTime.getFullYear()].join('/')+' '+
                [dCanadaTime.getHours(),
                    dCanadaTime.getMinutes(),
                    dCanadaTime.getSeconds()].join(':');
        var submittedDate = ddformatToday;

        console.log(comment);
        if (feeling == 'approve') {
            console.log('approve content is sumbitted');
            app.database().ref('channels/' + channelUuid + '/comments').push({
                approveComment: comment,
                timestamp: submittedDate
            })
            .then(() => {
                console.log('success');

                props.history.push({
                    pathname: '/submit/thankYou',
                    state: {
                        channelUuid : channelUuid,
                        feeling: 'normal',
                        channel: channel,
                    }
                });
            })
            .catch(function(err){console.log(err)});
            
        }
        else if (feeling == 'normal') {
            console.log('normal content is submitted');
            app.database().ref('channels/' + channelUuid + '/comments').push({
                normalComment: comment,
                timestamp: submittedDate
            })
            .then(() => {
                console.log('success');

                props.history.push({
                    pathname: '/submit/thankYou',
                    state: {
                        channelUuid : channelUuid,
                        feeling: 'normal',
                        channel: channel,
                    }
                });
            })
            .catch(function(err){console.log(err)});
        }
        else if (feeling == 'unapprove') {
            console.log('unapprove content is submitted');
            app.database().ref('channels/' + channelUuid + '/comments').push({
                unapproveComment: comment,
                timestamp: submittedDate
            })
            .then(() => {
                console.log('success');

                props.history.push({
                    pathname: '/submit/thankYou',
                    state: {
                        channelUuid : channelUuid,
                        feeling: 'normal',
                        channel: channel,
                    }
                });
            })
            .catch(function(err){console.log(err)});
        }
    }
    const handleSkip = () =>{
        props.history.push({
            pathname: '/submit/thankYou',
            state: {
                channelUuid : channelUuid,
                feeling: 'normal',
                channel: channel,
            }
        });
    }

    // handle change for textarea.
    const handleChange = (e) => {
        setComment(e.target.value);
        if (e.target.value.length > 0){
            setSubmittable(true)
        }else{
            setSubmittable(false)
        }

    }
    const submitButton = () =>{
        if (submittable){
            return(
                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }} >Submit</Button>
            )
        }else{
            return(
                <Button onClick={()=>{handleSkip()}} color="" variant="contained" style={{ width: '100%', height: '45px' }} >Skip</Button>
            )
        }
    }

    // show loading when page render FULLY.
    if( loading == true ) return (
        <Container style={{ display:'flex', height: '90vh'}}>
            <h1 style={{margin: 'auto'}}>Loading...</h1>
        </Container>
    );


    return (
        channelUuid ?
                <Container
                    maxWidth="xs"
                    style={{ marginTop: '80px' }}
                >
                    <Grid
                        container
                        item
                        md={12}
                        xs={12}
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            justify="center"
                            xs={12}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <img src={channel.logo} className="displayName" /><p>{channel.displayName}</p>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="row"
                            justify="center"
                            xs={12}
                        >
                            <p>Any Comments?</p>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            direction="column"
                            style={{ margin: '20px', textAlign: 'center' }}
                        >
                            <ValidatorForm
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <FormControl>
                                        <TextareaAutosize 
                                            rowsMin={6}
                                            style={{resize: 'none', fontSize: 'larger'}}
                                            name="context"
                                            onChange={handleChange}
                                            value={comment}
                                            required
                                        />
                                    </FormControl>
                                </FormGroup>
                                <br/>

                                {submitButton()}

                            </ValidatorForm>
                        </Grid>
                    </Grid>
                </Container>
            :
            ''
    );
}

export default withRouter(Comment);