import React, { useCallback, useContext, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../../firebase";
import { AuthContext } from "./Auth";
import {sharedAssets} from "../../assets";

import {
    Button,
    Grid,
    Container,
    FormControl,
    FormGroup,
    FormHelperText,
    Tabs,
    Tab,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Alert from '@material-ui/lab/Alert';
import './style/Login.scss';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        maxWidth: 400,
        margin: 'auto',
    },
}));

const useStylesForAlert = makeStyles((theme) => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop:theme.spacing(2),
            }
        },
    })
);


const Login = ({ history }) => {
    const classes = useStyles();
    const classesForAlert = useStylesForAlert();
    const [value, setValue] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        hospital: '',
        email: '', 
        password: '',
        repeatPassword: '',
        phone: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [foo, setFoo] = useState(false);

    const tabHandleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== formData.password) {
                return false;
            }
            return true
        });

        let search = window.location.search;
        let params = new URLSearchParams(search);
        setFoo(params.get('confirmed'));

    }, [formData]);

    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await app
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
                history.push("/dashboard");
            } catch (error) {
                alert(error);
            }
        },
        [history]
    );

    const handleSignUp = useCallback(async event => {
        setSubmitted(true);
        event.preventDefault();
        const { email, password, phone, name, hospital } = event.target.elements;

        console.log(email.value, password.value, phone.value, name.value, hospital.value);
        try {
            // create new user account with email and password
            await app
                .auth()
                .createUserWithEmailAndPassword(email.value, password.value)
                .then((authData) => {
                    console.log(authData.user.uid);
                    // this is data to save in the firebase
                    let todayDate = new Date().toDateString();
                    app.database().ref('users/' + authData.user.uid).set({
                        username : name.value,
                        email: email.value,
                        hospital: hospital.value,
                        phone: phone.value,
                        registered: todayDate,
                        plan: 'EBB',
                        planStart: todayDate,
                        monthlyUsage: 0
                    })
                    .then((data) => {
                        // redirect to send email verify page
                        window.location ='/emailverify';
                    }).catch(function(error) {
                        console.log(error);
                    });
                }).catch(function(error) {
                    console.log(error);
                });

            
        } catch (error) {
            alert(error);
        }
        setSubmitted(false);
    }, [history]);

    // const { currentUser } = useContext(AuthContext);
    // if (currentUser) {
    //     return <Redirect to="/" />;
    // }

    const handleChange = (e) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[e.target.name] = e.target.value;
        setFormData(form_data);
    }


    return (
        <Container maxWidth="lg">
            <Grid
                container
                spacing={1}
                item
                xs={12}
                direction="row"
                justify="center"
            >
                <div className={classesForAlert.root}>
                    {foo ? <Alert severity="success" style={{margin: '15px'}}>Account Confirmed, Please Login</Alert> : ''}
                </div>
                <Grid
                    container
                    spacing={1}
                    item
                    xs={0}
                    md={6}
                    direction="row"
                    justify="center"
                >
                    <div className="auth-background"></div>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    direction="row"
                    justify="center"
                    style={{ margin: 'auto' }}
                    id="auth_panel"
                >
                    <div style={{display:"block"}}>{sharedAssets.logo}</div>

                    <div className={classes.root}>
                        <AppBar position="static">
                            <Tabs value={value} onChange={tabHandleChange} aria-label="Auth tabs" TabIndicatorProps={{style: {background:'#ffc600'}}}>
                                <Tab label="Login" {...a11yProps(0)} style={{ width: '50%' }} />
                                <Tab label="Sign Up" {...a11yProps(1)} style={{ width: '50%' }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <ValidatorForm 
                                onSubmit={handleLogin}
                                useRef="form"
                            >
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Email"
                                            name="email"
                                            onChange={handleChange}
                                            value={formData.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['this field is required', 'email is not valid']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Password"
                                            name="password"
                                            type="password"
                                            onChange={handleChange}
                                            value={formData.password}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <br />
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }}>
                                    Login
                                        </Button>
                            </ValidatorForm>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ValidatorForm
                                onSubmit={handleSignUp}
                                useRef="form"
                            >
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator
                                            label="Name"
                                            name="name"
                                            aria-describedby="name-helper-text"
                                            onChange={handleChange}
                                            value={formData.name}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                        <FormHelperText id="name-helper-text">Your name</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Business Name"
                                            name="hospital"
                                            onChange={handleChange}
                                            value={formData.hospital}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Email"
                                            name="email"
                                            onChange={handleChange}
                                            aria-describedby="email-helper-text"
                                            value={formData.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['this field is required', 'email is not valid']}
                                        />
                                        <FormHelperText id="email-helper-text">You will use this email to login</FormHelperText>
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Phone Number"
                                            name="phone"
                                            onChange={handleChange}
                                            value={formData.phone}
                                            validators={['required', 'isNumber']}
                                            errorMessages={['this field is required', 'phone number is not valid']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Password"
                                            name="password"
                                            type="password"
                                            onChange={handleChange}
                                            value={formData.password}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormControl>
                                        <TextValidator 
                                            label="Confirm password"
                                            name="repeatPassword"
                                            type="password"
                                            validators={['required', 'isPasswordMatch']}
                                            errorMessages={[ 'this field is required', 'password mismatched' ]}
                                            onChange={handleChange}
                                            value={formData.repeatPassword}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <br />

                                <span>
                                    <p className="legalAgreement">By continuing you agree to our&nbsp;</p>
                                    <p className="legalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{history.push('/terms')}}>Terms of Use&nbsp;</a></p>
                                    <p className="legalAgreement">and&nbsp;</p>
                                    <p className="legalAgreement" style={{cursor: "pointer"}}><a onClick={()=>{history.push('/privacy')}}>Privacy Policy</a></p>
                                </span>
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }}>
                                    Continue
                                </Button>
                            </ValidatorForm>
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>

        </Container>



    );
};

export default withRouter(Login);