import React, { useEffect } from 'react';
import { Container, Button, Grid } from '@material-ui/core';
import app from "../../firebase";
import { withRouter } from 'react-router';

const EmailVerify = (props) => {

    useEffect( ()=> {
        setTimeout(function(){
                    if (app.auth().currentUser !== null && app.auth().currentUser.emailVerified == false) {
                        console.log("Sent verify email in starting");
                        app.auth().currentUser.sendEmailVerification({
                            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
                        });
                    } else if(app.auth().currentUser !== null && app.auth().currentUser.emailVerified == true) {
                        props.history.push('/');
                    }
                    else {
                        props.history.push('/login');
                    }   
        }, 800);
       
    }, []);

    const handleSubmit = () => {
        if (app.auth().currentUser !== null) 
        {
            console.log("Sent verify email");
            app.auth().currentUser.sendEmailVerification({
                url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
            });
        }
      
    }

    return (
        <Container maxWidth="lg">
            <Grid
                container
                item
                md={12}
                xs={12}
                direction="row"
            >
                <Grid
                    container
                    item
                    md={3}
                >
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    <p>
                        One Last Step...
                    </p>
                    <p>
                        Confirmation message was sent.&nbsp;
                        <span style={{ color: 'blue'}}>Please confirm your email!</span>
                    </p>
                    <Button onClick={handleSubmit} color="primary" variant="contained">Resend</Button>
                    <p style={{marginTop:"40px"}}>also check your spam box, this is particularly true for Outlook users.</p>
                    <p>If you are still having trouble contact support@1mpressions.com</p>
                </Grid>
            </Grid>
        </Container>
    );
}

export default withRouter(EmailVerify);
