import React from "react";

import styled from "styled-components";

import Demo from "./Icons/Demo";

import landing1 from "../assets/img/landing1.png"
import landing2 from "../assets/img/landing2.png"
import landing3 from "../assets/img/landing3.png"

const Section = styled.section`
  padding: 30px 0 100px;
`;
const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  color: #1b5cce;
  text-align: center;
  margin-bottom: 2px;
`;

const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: #aeaeae;
  margin-bottom: 25px;
  @media (min-width: 992px) {
    margin-bottom: 50px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  &:hover {
    transform: translateY(-5px) !important;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

const IconWrap = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: solid 1px #eff2f9;
  background-color: #f8faff;
  margin-bottom: 30px;
  position: relative;
  > svg {
    transition: all 0.3s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    > svg {
      transform: translate(-50%, -50%) rotateY(360deg);
    }
  }
`;

const BoxTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: #5273c7;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.58;
  color: #8f8f8f;
  margin-bottom: 0;
  max-width: 350px;
`;

const About = () => {
  const width = window.innerWidth;
  return (
    <Section id="about">
      <div className="container" style={{paddingTop:'55px'}}>
        <SectionTitle>Empowering businesses with data</SectionTitle>
        <SubTitle>Through enabling your business with feedback tools, we hope to help you create a more seamless experience for your customers. </SubTitle>
        <div className="row">
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-right" : "fade-up"}>

              <img src={landing1} style={{width:"60%", height:"auto"}}/>

              <BoxTitle>Your Business, Your feedback</BoxTitle>
              <Text>
                3 feedback channels,
                completely customizable, absolutely for free.
                Get the type of feedback that matters to your business
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos="fade-up">

              <img src={landing2} style={{width:"60%", height:"auto"}}/>

              <BoxTitle>Free for Essential Businesses</BoxTitle>
              <Text>
                No credit cards, simple sign-up.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <img src={landing3} style={{width:"60%", height:"auto"}}/>
              <BoxTitle>Data, made simple</BoxTitle>
              <Text>
                No complex graphs, charts, or numbers.
                Learn what your customers hate,
                what they love, and what they REALLY love.
              </Text>
            </Box>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default About;
