import React from 'react';
import { withRouter } from 'react-router';
import './Legal.css'
import PdfPolicy from './goodBiiPrivacyPolicy.pdf';

const Privacy = () => {

    return (
        <div className="legalClass">
            <p>When you interact with our site, your IP address and location are collected. This site uses cookies.</p>
            <p>We collect this information on behalf of our clients and to improve our services/prevent abuse</p>
            <a href = {PdfPolicy} target = "_blank">See our Privacy Policy for all the details</a>

        </div>

    )
}

export default withRouter(Privacy)