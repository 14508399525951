import React, { useEffect, useState } from "react";
import app from "../firebase";
import {
    Container,
    Grid,
    Card,
    CardContent,
    Modal,
    Button,
    FormGroup,
    FormControl
} from "@material-ui/core";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import './Home.scss';
//ilogo is the default logo
import ilogo from '../assets/img/logoDefault.png'
import upLoaderLogo from '../assets/img/uploader.png';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import QRCode from 'qrcode.react';
import uuid from 'react-uuid';
import {Plans} from '../Standards'
import QRCreate from "./qr_code_studio/QR_Create";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    displayName: {
        position: 'absolute',
        width: 300,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    responseModal: {
        position: 'absolute',
        width: 300,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        border: '4px solid #3f51b5',
        display: 'flex'
    }
}));

/////////////////////////////////////////TABS BEGIN/////////////////////////////////////////
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStylesTabs = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,

    },
    tab: {
        '& .MuiBox-root': {
            padding: '5px',
            marginTop:"14px"
        },
    },

}));

/////////////////////////////////////////TABS END/////////////////////////////////////////

const Home = (props) => {
    const tabClasses = useStylesTabs();
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeTabIndex = (index) => {
        setTabValue(index);
    };
    //DEFAULT TAB
    const [hospitalName, setHospitalName] = useState('');

    const [planCode, setPlanCode] = useState('')
    const [planModal, setPlanModal] = useState(false);
    const [planChannelMaxModal, setPlanChannelMaxModal] = useState(false);

    const [logoModal, setLogoModalOpen] = useState(false);
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [logoPic, setLogoPic] = useState('');
    const [logo, setLogo] = useState(ilogo);
    const [changedTempLogo, setChangedTempLogo] = useState(upLoaderLogo);
    const allImputs = { imgUrl: '' }
    const [imageAsUrl, setImageAsUrl] = useState(allImputs);
    const [logoName, setLogoName] = useState('');
    const [displayNameModal, setDisplayNameModal] = useState(false);
    const [hospital, setHospital] = useState('');
    const [userID, setUserID] = useState('');
    const [channels, setChannels] = useState('');
    const [userChannels, setUserChannels] = useState('');
    const [deleteResponseModal, setDeleteResponseModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [channelDeleteModalIndex, setChannelDeleteModalIndex] = useState();

    //covid-19 tools TAB
    const [attendance, setAttendance] = useState('')




    useEffect(() => {
        app.auth().onAuthStateChanged(user => {
            setLoading(true);
            if (user && user.emailVerified) {
                let userId = user.uid
                // Set USER ID globally.
                setUserID(userId);
                // get users profile data from database
                app.database().ref('users/' + userId).once('value', snapshot => {
                    if (snapshot && snapshot.exists()) {
                        console.log(snapshot.val());
                        // update hospital state
                        setHospitalName(snapshot.val().hospital);

                        setPlanCode(snapshot.val().plan);
                        
                        // update logoName state    
                        if (typeof snapshot.val().logo !== 'undefined') {
                            let logo_name = snapshot.val().logo.logoName ? snapshot.val().logo.logoName : '';
                            setLogoName(logo_name);
                            let logo_url = snapshot.val().logo.logoUrl ? snapshot.val().logo.logoUrl : ilogo;
                            // update imageAsUrl state     
                            setImageAsUrl(prevObject => ({ ...prevObject, imgUrl: logo_url }));
                            setLogo(logo_url);
                        }else{
                            let logo_url = "https://firebasestorage.googleapis.com/v0/b/mpressions-bf.appspot.com/o/images%2F1001002030e0340dff200defaultLogo.png?alt=media&token=a7dc9301-952b-4c8a-8f34-bd541f2b2503"
                            setImageAsUrl(prevObject => ({ ...prevObject, imgUrl: logo_url }));

                        }
                        // update display name change modal , hospital name state
                        setHospital(snapshot.val().hospital);
                        // update channel state

                        // clear the history of the channels state
                        setChannels('');
                        // clear the history of the users/channel uuid state
                        setUserChannels('');
                        // clear the attendance state
                        setAttendance('')

                        if (snapshot.val().channels) {
                            app.database().ref('users/' + userId + '/channels').once('value', function (snap) {
                                snap.forEach(function (childSnapshot) {
                                    let childKey = childSnapshot.key;
                                    // this is the channel uuid json
                                    let childData = childSnapshot.val();
                                    console.log(childKey, childData);
                                    
                                    // get the channel details from database/channels/userid/channelId
                                    app.database().ref('channels/' + childData.channelId).once('value', item => {
                                        if (item && item.exists()) {
                                            // add the channels data in channels state.
                                            setChannels(oldArray => [...oldArray, item.val()]);
                                            // add the usesrs channel uuids in userchannel state
                                            setUserChannels(oldArray => [...oldArray, childKey]);
                                        }
                                    });
                                });

                                setLoading(false);

                            });
                        }
                        app.database().ref('attendanceV1/prompts/' + userId).once('value', item => {
                            if (item && item.exists()) {
                                setAttendance(item.val())
                                setLoading(false);
                            }else{
                                setLoading(false);
                            }

                            console.log("attendanceV1 initiated")

                        });

                    }
                });
            } else if (user && !user.emailVerified) {
                props.history.push('/emailverify');
            } else {
                props.history.push('/login');
            }
        });
    }, [logoPic])

    // handle change for display name input field
    const hospitalHandleChange = (e) => {
        setHospital(e.target.value);
    }

    // open change logo modal
    const logoModalOpen = () => {
        setLogoModalOpen(true);
    }
    // close change logo modal
    const logoModalClose = () => {
        setLogoModalOpen(false);
    }
    // open change display name modal
    const changeHospitalNameModalOpen = () => {
        setDisplayNameModal(true);
    }
    // close change display name modal 
    const changeHospitalNameModalClose = () => {
        setDisplayNameModal(false);
    }
    //open plan info
    const changePlanOpen = () => {
        setPlanModal(true);
    }
    // close plan info
    const changePlanClose = () => {
        setPlanModal(false);
    }
    // open max modal
    const changePlanChannelMaxOpen = () => {
        setPlanChannelMaxModal(true);
    }
    // close max info
    const changePlanChannelMaxClose = () => {
        setPlanChannelMaxModal(false);
    }
    // open delete response modal 
    const deleteResponseModalOpen = (index) => {
        console.log(index);
        setChannelDeleteModalIndex(index);
        setDeleteResponseModal(true);
    }
    // close delete response modal
    const deleteResponseModalClose = () => {
        setDeleteResponseModal(false);
    }

    // update display name in firebase
    const updateDisplayName = () => {
        console.log("Start display name change");
        console.log(hospital, userID);
        app.database().ref('users/' + userID).update({
            hospital: hospital
        })
            .then(() => {
                // update display name in the database/channels/uuid table
                if ( channels ) {
                    for (var i = 0; i < channels.length; i++)
                    {
                        console.log(channels[i].uuid);
                        app.database().ref('channels/' + channels[i].uuid).update({
                            displayName: hospital
                        });
                    }
                }
                if (attendance){
                    app.database().ref('attendanceV1/prompts/'+userID).update({
                        displayName: hospital
                    });
                }
                console.log("Success display name(hospital update)");
                setDisplayNameModal(false);
            })
            .catch(function (err) { console.log(err) });
    }
    // detect file input tag value changing or not.
    const changeLogoPic = (e) => {
        console.log(e.target.files[0]);
        const image = e.target.files[0];
        setLogoPic(image);
        const [file] = e.target.files;
        // if (file) document.getElementById('logoPic').src = window.URL.createObjectURL(file);
        if (file) setChangedTempLogo(window.URL.createObjectURL(file));
    }
    // upload logo to firebase
    const updateLogo = (e) => {
        e.preventDefault();
        console.log("uploading logo start");

        if (logoPic == '') {
            alert('Please select image for upload');
        }
        else {
            // show load gif when start upload
            document.getElementById('logoModalWrapper').style.visibility = 'hidden';
            document.getElementById('load_gif_logo').style.visibility = 'visible';

            // async magic goes here...
            if (logoPic === '') {
                console.error(`not an image, the image file is a ${typeof (logoPic)}`);
            }
            // put logoPic to firebase Storage
            console.log(logoPic);
            let uniqueLogoPicName = logoPic.name + uuid();
            console.log(uniqueLogoPicName);
            const uploadTask = app.storage().ref(`/images/${uniqueLogoPicName}`).put(logoPic);
            // initiates the firebase side uploading
            uploadTask.on('state_changed', (snapshot) => {
                console.log("Started to Firebase Storage Uploading logo image");
            }, (err) => {
                console.log("Failed logo Upload to firebase storage", err);
                setLogoModalOpen(false);
            }, () => {
                console.log("Completed in Firebase Storage Uploading logo image")
                // gets the functions from storage refences the image sotrage in the firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                app.storage().ref('images').child(uniqueLogoPicName).getDownloadURL()
                    .then(fireBaseUrl => {
                        console.log("Get DownLoadable URL from FireBase Storage", fireBaseUrl);
                        console.log(userID);
                        // delete original uploaded logo image.
                        console.log('LOGO NAME', logoName);
                        let imageRef = app.storage().ref('/images/' + logoName);
                        imageRef
                            .delete()
                            .then(() => console.log(`${logoName}has been deleted successfully.`))
                            .catch((e) => console.log('error on image deletion => ', e));

                        // save or update logo image url in the database/channels/uuid table
                        if ( channels ) {
                            for (var i = 0; i < channels.length; i++)
                            {
                                console.log(channels[i].uuid);
                                app.database().ref('channels/' + channels[i].uuid).update({
                                    logo: fireBaseUrl
                                });
                            }
                        }
                        //update attendanceV1 logo
                        if (attendance){
                            app.database().ref('attendanceV1/prompts/'+userID).update({
                                logo: fireBaseUrl
                            });
                        }

                        // save uploaded logo image url and name in authenticated users' table.
                        app.database().ref('users/' + userID + '/logo').set({
                            logoUrl: fireBaseUrl,
                            logoName: uniqueLogoPicName
                        }).then(() => {
                            // update current page logo image.
                            setImageAsUrl(prevObject => ({ ...prevObject, imgUrl: fireBaseUrl }));
                            // update the paramater of logo image when go to another page.
                            setLogo(fireBaseUrl);
                            // show modal and hide loader gif
                            document.getElementById('logoModalWrapper').style.visibility = 'visible';
                            document.getElementById('load_gif_logo').style.visibility = 'hidden';
                            setLogoModalOpen(false);
                        }).catch(function (error) { console.log(error) });
                    });
            });
        }
    }


    // Clear Responses in one Channel.
    const clearResponses = (index) => {
        if (index != "attendance") {
            console.log('Clear Responses');
            console.log(channels[index]);
            app.database().ref('channels/' + channels[index].uuid + '/responses').set({
                approve: 0,
                normal: 0,
                unapprove: 0
            });

            app.database().ref('channels/' + channels[index].uuid + '/comments').remove();
            app.database().ref('channels/' + channels[index].uuid + '/timeBlocks').remove();
        }else{
            clearAttendanceResponses()
        }
        
        window.location.reload();
    }
    // delete one channel.
    const deleteChannel = (index) => {
        if (index != "attendance") {
            console.log('Delete Chanel');
            console.log(channels[index]);
            console.log(userChannels[index]);
            app.database().ref('channels/' + channels[index].uuid).remove();
            app.database().ref('users/' + userID + '/channels/' + userChannels[index]).remove();
        }else{
            deleteAttendance()
        }

        window.location.reload();
    }
    //Clear responses in an attendance channel
    const clearAttendanceResponses = () =>{
        app.database().ref('attendanceV1/responses/'+userID+'/log').remove();
    }

    //Delete an attendance channel
    const deleteAttendance = () =>{
        app.database().ref('attendanceV1/responses/'+userID).remove();
        app.database().ref('attendanceV1/prompts/'+userID).remove();
    }
    // redirect to New Channel page
    const goToNewPage = () => {
        let plan = planCode;
        if (plan === ''){
            plan = "ESS"
        }
        let limit = Plans[plan].max_channels
        if (channels.length < limit){
            props.history.push({
                pathname: 'dashboard/new',
                state: {
                    userId: userID,
                    displayName: hospitalName,
                    logoUrl: logo
                }
            })
        }else{
            changePlanChannelMaxOpen()
        }

    }
    const goToNewAttendance = () =>{
        props.history.push({
            pathname: 'dashboard/newAttendance',
            state: {
                userId: userID,
                displayName: hospitalName,
                logoUrl: logo
            }
        })
    }
    //AttendanceBlock
    const attendanceBlock = () =>{
        if (attendance){
            return(
                <Card
                    style={{ border: '2px solid #5454c7', borderRadius: '20px', marginBottom: '10px' }} index={9999}
                >
                    <CardContent
                        style={{ paddingBottom: '0px' }}
                    >
                        <Grid
                            container
                            md={12}
                            direction="row"
                        >
                            <Grid
                                container
                                item
                                md={9}
                                direction="column"
                            >
                                <p className="dashboard-header">{attendance.prompt}</p>

                                <p style={{ marginBottom: '0px', marginTop: '76px' }} id="edit_para_channel">
                                    <span onClick={() => goToAttendance()}>responses</span>
                                    <span onClick={() => goToEditAttendance()}>edit</span>
                                    <span onClick={() => deleteResponseModalOpen("attendance")}>delete</span>
                                </p>
                            </Grid>
                            <Grid
                                container
                                item
                                md={3}
                                direction="column"
                                style={{ alignItems: 'flex-end' }}
                            >
                                <QRCode
                                    id="attendance"
                                    value={`https://goodbii.com/at1/${attendance.uuid}`}
                                    size={100}
                                    level={'H'}
                                    includeMargin={true}
                                />
                                <a onClick={() => downloadQR('attendance')} style={{ cursor: 'pointer', color: 'blue' }}>download QR</a>
                                <a onClick={() => openQRCodeCreator(`https://goodbii.com/at1/${attendance.uuid}`)} style={{ cursor: 'pointer', color: 'blue' }}>customize and download</a>
                                <a onClick={() => {navigator.clipboard.writeText(`https://goodbii.com/at1/${attendance.uuid}`)}}
                                   className="copyToClip" style={{ cursor: 'pointer', color: 'blue' }}>copy link to clipboard</a>
                                <a onClick={() => props.history.push(`/at1/INTERNAL_MODE${attendance.uuid}`)}
                                   style={{ cursor: 'pointer', color: 'blue', marginBottom:"40px" }}>open for in-house use</a>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>

            )
        }
    }
    // redirect to Response page
    const goToResponse = (index) => {
        props.history.push({
            pathname: 'dashboard/response',
            state: {
                userId: userID,
                channel: channels[index],
            }
        });
    }
    // redirect to Edit channel page
    const goToEditChannel = (index) => {
        props.history.push({
            pathname: 'dashboard/edit',
            state: {
                userId: userID,
                displayName: hospitalName,
                logoUrl: logo,
                channel: channels[index],
            }
        })
    }
    const goToEditAttendance = () =>{
        props.history.push({
            pathname: 'dashboard/newAttendance',
            state: {
                userId: userID,
                displayName: hospitalName,
                logoUrl: logo,
                existingPrompt: attendance.prompt,
                getPartySize:attendance.getPartySize,
                getTableLocation:attendance.getTableLocation
            }
        })
    }
    const goToAttendance = () => {
        props.history.push({
            pathname: 'dashboard/attendances',
            state: {
                userId: userID
            }
        });
    }

    const downloadQR = (idParam) => {
        console.log(idParam);
        const canvas = document.getElementById(idParam);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrCode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const [qrCodeMode, setQRCodeMode] = useState(false);
    const [qrCodePresets, setQRCodePresets] = useState({});
    const openQRCodeCreator = (channelLink) =>{

        setQRCodePresets( {
            id: "undef",
            link: channelLink,
            logoLink: logo
        })

        setQRCodeMode(true)
    }

    const qrCodeCreator = () =>{
        return(
                <div>
                    <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
                        <Button variant="contained" color="default" onClick={()=>setQRCodeMode(false)}>X</Button>
                    </div>
                <QRCreate presets={qrCodePresets}/>
            </div>
        )
    }

    // show loading when page render FULLY.
    if ( loading == true ) return (
        <Container style={{ display:'flex', height: '90vh'}}><h1 style={{margin: 'auto'}}>Loading...</h1></Container>
    );

    else if (qrCodeMode == true) return (
       qrCodeCreator()

    );

    else return (
        <Container maxWidth="lg" style={{marginTop:"20px"}}>
            {/* Logo Change Modal */}
            <Modal
                open={logoModal}
            >
                <div style={modalStyle} className={classes.paper} id="logoModal">
                    <div id="load_gif_logo" style={{ visibility: 'hidden' }}></div>
                    <div id="logoModalWrapper">
                        <p style={{ textAlign: 'center', marginTop: '50px' }}><b>Please upload a square logo (PNG, JPEG, SVG)</b></p>
                        <form onSubmit={updateLogo}>
                            <div className="logoPic">
                                <input
                                    type="file"
                                    name="file"
                                    accept="image/jpg,image/png,image/svg,image/jpeg"
                                    multiple
                                    id="contained-button-file"
                                    onChange={changeLogoPic}
                                />
                                <label htmlFor="contained-button-file">
                                    {/* <img id="logoPic" src={logo} alt="dashboard logo"></img> */}
                                    <img id="logoPic" src={changedTempLogo} alt="dashboard logo"></img>
                                </label>
                            </div>
                            <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }} >ok</Button>
                        </form>
                        <Button onClick={logoModalClose} color="default" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px' }} >cancel</Button>
                    </div>

                </div>
            </Modal>
            {/* display name change modal */}
            <Modal
                open={displayNameModal}
            >
                <div style={modalStyle} className={classes.displayName} id="logoModal">
                    <div id="load_gif_logo" style={{ visibility: 'hidden' }}></div>
                    <div id="logoModalWrapper">
                        <p style={{ textAlign: 'center', marginTop: '50px' }}><b>Change display name</b></p>
                        <br />
                        <ValidatorForm
                            onSubmit={updateDisplayName}
                            useRef="form"
                        >
                            <FormGroup>
                                <FormControl>
                                    <TextValidator
                                        label="Display name"
                                        name="hospital"
                                        onChange={hospitalHandleChange}
                                        value={hospital}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                </FormControl>
                            </FormGroup>
                            <br />
                            <br />
                            <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }} >ok</Button>
                        </ValidatorForm>
                        <Button onClick={changeHospitalNameModalClose} color="default" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px', marginBottom: '10px' }} >cancel</Button>
                    </div>

                </div>
            </Modal>
            {/* delete or reset response modal */}
            <Modal
                open={deleteResponseModal}
            >
                <div style={modalStyle} className={classes.responseModal} id="logoModal">
                    <div id="load_gif_logo" style={{ visibility: 'hidden' }}></div>
                    <div id="logoModalWrapper" style={{ margin: 'auto' }}>
                        <Button onClick={() => clearResponses(channelDeleteModalIndex)} color="secondary" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px', marginBottom: '10px' }} >clear responses</Button>
                        <Button onClick={() => deleteChannel(channelDeleteModalIndex)} color="secondary" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px', marginBottom: '10px' }} >delete this channel and it's responses</Button>
                        <Button onClick={deleteResponseModalClose} color="default" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px', marginBottom: '10px' }} >cancel</Button>
                    </div>

                </div>
            </Modal>
            {/* plan modal */}
            <Modal
                open={planModal}
            >
                <div style={modalStyle} className={classes.displayName} id="logoModal">

                    <h1>Plan: {Plans[planCode ? planCode : "ESS"].name}</h1>
                    <p className="planDetails">Max Channels: {Plans[planCode ? planCode : "ESS"].max_channels}</p>
                    <p className="planDetails">Monthly interaction limit: {Plans[planCode ? planCode : "ESS"].month_interacts}</p>
                    <p className="planDetails">{Plans[planCode ? planCode : "ESS"].description}</p>
                    <br/>
                    <p className="planDetails">contact support if your business needs more</p>
                    <Button onClick={changePlanClose} color="default" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px', marginBottom: '10px' }} >close</Button>

                </div>
            </Modal>
            {/* plan max modal */}
            <Modal
                open={planChannelMaxModal}
            >
                <div style={modalStyle} className={classes.displayName} id="logoModal">
                    <h1>Max channels reached</h1>
                    <p className="planDetails">Plan: {Plans[planCode ? planCode : "ESS"].name}</p>
                    <p className="planDetails">Max Channels: {Plans[planCode ? planCode : "ESS"].max_channels}</p>
                    <p className="planDetails">Monthly interaction limit: {Plans[planCode ? planCode : "ESS"].month_interacts}</p>
                    <br/>
                    <p className="planDetails">contact support if you need more feedback channels</p>
                    <Button onClick={changePlanChannelMaxClose} color="default" variant="contained" style={{ width: '100%', height: '45px', marginTop: '5px', marginBottom: '10px' }} >close</Button>

                </div>
            </Modal>

            <Grid
                container
                item
                md={12}
                direction="row"
                justify="center"
            >
                <Grid
                    container
                    item
                    md={6}
                    xs={12}
                >
                </Grid>
                <Grid
                    container
                    md={6}
                    xs={12}
                    item
                    direction="column"
                    justify="center"
                    style={{ textAlign: 'right' }}
                >
                    <h2 id="logoPara">
                        <img src={imageAsUrl.imgUrl} style={{ width: '50px', height:'50px', borderRadius: '50%', marginRight: '8px' }} />
                        <span>Welcome {hospitalName ? hospitalName : ''}</span>
                    </h2>
                    <p className="dashboard-actions">
                        <span  onClick={changePlanOpen} style={{ color: 'blue'}}>{planCode ? Plans[planCode].name : ''}</span>
                    </p>

                    <p className="dashboard-actions" >
                        <span onClick={logoModalOpen} style={{ color: '#101010' }}>change logo</span>
                    </p>
                    <p className="dashboard-actions" >
                        <span onClick={changeHospitalNameModalOpen} style={{ color: '#101010' }}>change display name</span>
                    </p>

                </Grid>
            </Grid>

            <div style={{marginTop:"20px"}} className={tabClasses.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                    >
                        <Tab label="Feedback Channels" {...a11yProps(0)} />
                        <Tab label="Pandemic Tools" {...a11yProps(1)} />
                        <Tab label="Resource Library" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tabValue}
                    onChangeIndex={handleChangeTabIndex}
                >
                    <TabPanel value={tabValue} index={0} dir={theme.direction} className={ tabClasses.tab }>
                        <Grid
                            container
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            direction="row"
                            justify="left"
                        >

                            <p style={{marginTop:"10px"}}>feedback channels {channels ? <span>{channels.length}/{planCode ? Plans[planCode].max_channels : "0"}</span> : <span>0/0</span>}</p>
                        </Grid>
                        <Grid
                            container
                            lg={12}
                            md={12}
                            direction="column"
                            style={{ border: '0px solid #9a9797', padding: '0px', minHeight: '0vh' }}
                        >
                            {
                                channels
                                    ?
                                    channels.map((channel, index) => {
                                        return (
                                            <Card
                                                style={{ border: '2px solid #5454c7', borderRadius: '20px', marginBottom: '10px' }} index={index}
                                            >
                                                <CardContent
                                                    style={{ paddingBottom: '0px' }}
                                                >
                                                    <Grid
                                                        container
                                                        md={12}
                                                        direction="row"
                                                    >
                                                        <Grid
                                                            container
                                                            item
                                                            md={9}
                                                            direction="column"
                                                        >
                                                            <p className="dashboard-header">{channel.question} ({channel.internalName})</p>

                                                            <p style={{ marginBottom: '0px', marginTop: '76px' }} id="edit_para_channel">
                                                                <span onClick={() => goToResponse(index)}>responses</span>
                                                                <span onClick={() => goToEditChannel(index)}>edit</span>
                                                                <span onClick={() => deleteResponseModalOpen(index)}>delete</span>
                                                            </p>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            md={3}
                                                            direction="column"
                                                            style={{ alignItems: 'flex-end' }}
                                                        >
                                                            <QRCode
                                                                id={`123456${index}`}
                                                                value={`https://goodbii.com/id/${channel.uuid}`}
                                                                size={100}
                                                                level={'H'}
                                                                includeMargin={true}
                                                            />
                                                            <a onClick={() => downloadQR('123456'+index)} style={{ cursor: 'pointer', color: 'blue' }}>download QR</a>
                                                            <a onClick={() => openQRCodeCreator(`https://goodbii.com/id/${channel.uuid}`)} style={{ cursor: 'pointer', color: 'blue' }}>customize and download</a>
                                                            <a onClick={() => {navigator.clipboard.writeText(`https://goodbii.com/id/${channel.uuid}`)}}
                                                               className="copyToClip" style={{ cursor: 'pointer', color: 'blue', marginBottom:"40px" }}>copy link to clipboard</a>
                                                        </Grid>

                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )
                                    })
                                    :
                                    ''
                            }
                            <span style={{ textAlign: 'center', color: 'blue', cursor: 'pointer' }} onClick={goToNewPage}>
                    { channels? <span>add new channel</span> : <span>You have no channels yet! Click here to get started</span>}
                </span>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} dir={theme.direction} className={ tabClasses.tab }>
                        <p style={{marginTop:"10px"}}> <span>attendance list (pandemic contact tracing)</span></p>

                        <Grid
                            container
                            md={12}
                            lg={12}
                            direction="column"
                            style={{ border: '0px solid #9a9797', padding: '0px', minHeight: '0vh' }}
                        >
                            {attendanceBlock()}
                            <span >
                    { attendance? <span></span> :
                        <span style={{ textAlign: 'center', color: 'blue', cursor: 'pointer' }} onClick={goToNewAttendance}>No attendance list yet! Click here to get started</span>}
                </span>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2} dir={theme.direction} className={ tabClasses.tab }>
                        no classes or recommended resources yet. stay tuned!
                    </TabPanel>
                </SwipeableViews>
            </div>










            <div style={{ width: '100%', height: '45px', marginTop: '20px', marginBottom: '20px', textAlign: 'right'}}>

                <Button type="submit" color="primary" variant="contained" onClick={() => app.auth().signOut()}>log out</Button>
            </div>

        </Container>
    );
};

export default Home;