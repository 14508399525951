import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {Container, Grid, Switch, FormGroup, FormControlLabel, Button} from '@material-ui/core';
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import {sharedAssets} from "../assets";
import './Response.scss';
import app from '../firebase';
import {CSVLink, CSVDownload} from 'react-csv';



const AttendanceLogs = (props) => {

    const [channel, setChannel] = useState('');
    const [userID, setUserID] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        console.log(props);
        if (props.location.state != undefined) {
            setComments('');
            setChannel(props.location.state.channel);
            setUserID(props.location.state.userId);
            console.log(props.location.state.userId)
            if (props.location.state.userId) {
                app.database().ref('attendanceV1/responses/' + props.location.state.userId + '/log').once('value', function (snap) {
                    snap.forEach(function (childSnap) {
                        console.log(childSnap.key, childSnap.val());
                        if (childSnap.val()) {
                            setComments(oldArray => [...oldArray, childSnap.val()]);

                        }
                    });
                })
                    .then(() => {
                        console.log('success get comments');

                        setLoading(false);

                    })
                    .catch(function (err) {
                        console.log('error');
                        console.error(err);
                        setLoading(false);
                    });

            } else {
                console.log('comments is empty');
                setComments('');
                setLoading(false);
            }

        } else {
            console.log('undefined state');
            setLoading(false);
        }
    }, []);




    // show loading when page render FULLY.
    if (loading == true) return (
        <Container style={{display: 'flex', height: '90vh'}}><h1 style={{margin: 'auto'}}>Loading...</h1></Container>
    );

    return (
        userID ?
            <div style={{marginLeft: "5%", marginRight: "5%", marginTop: "1%", marginBottom: "5%"}}>

                <Link to="/dashboard">
                    <Button style={{marginBottom:"15px", marginTop:"15px"}} type="submit" color="primary" variant="contained" onClick={() => "/dashboard"}>⇦</Button>
                </Link>



                <CardGroup style={{marginTop:"30px", marginBottom:"30px"}}>
                    <Card style={{borderStyle: "solid", borderColor: "#ffffff", borderRadius: "0px"}}><Card.Body>
                    <Container
                        maxWidth="md"
                        style={{display: 'flex'}}
                    >
                        <div style={{margin: 'auto'}}>


                            <Grid
                                container
                                item
                                direction="column"
                                md={12}
                            >
                                <CSVLink data={comments} >Download attendance data (CSV)</CSVLink>
                                <h3>Raw Data Preview</h3>
                                {

                                    comments ?
                                        comments.map((comment, index) => {
                                            return (
                                                <div index={index}>
                                                    <Card>
                                                            <p className="responsePara"><span>{comment.name}</span> <span className="minorDetails">({comment.timeStamp})</span>
                                                            </p>
                                                        <p className="minorDetails">Contact: {comment.number}</p>
                                                        <p className="minorDetails">Party Size: {comment.partySize}</p>
                                                        <p className="minorDetails">Location: {comment.tableLocation}</p>

                                                    </Card>

                                                </div>
                                            )
                                        })
                                        :
                                        'No responses yet, but attendance logging is enabled'
                                }
                            </Grid>


                            <Grid
                                containter
                                item
                                md={12}
                                style={{marginTop: '20px'}}
                            >

                            </Grid>
                        </div>
                    </Container>
                    </Card.Body></Card>
                </CardGroup>


                <Link to="/dashboard">
                    <Button style={{marginBottom:"15px", marginTop:"15px"}} type="submit" color="primary" variant="contained" onClick={() => "/dashboard"}>⇦</Button>
                </Link>

            </div>
            :
            <Container
                maxWidth="xs"
                style={{display: 'flex', height: '90vh'}}
            >
                <h1 style={{margin: 'auto', textAlign: 'center'}}>
                    Page Not Found.
                    <br/>
                    404
                </h1>
            </Container>
    );
}

export default withRouter(AttendanceLogs);