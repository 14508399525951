import React from "react"
import './styles.css'
import logoImage from './img/logomed.png'

import approve from './img/emojiapprove.png'
import neutral from './img/emojinormal.png'
import un_approve from './img/emojiunapprove.png'

const sharedAssets = {
    "logo": <img src={logoImage} className="logo"/>,
    "logoSmaller": <img src={logoImage} className="logoSmaller"/>,
    "logo_nav":<img src={logoImage} className="logoNav"/>,
    "thumbUp":<img src={approve} className="emojiI"/>,
    "thumbDown":<img src={un_approve} className="emojiI"/>,
    "thumbNeutral":<img src={neutral} className="emojiI"/>,

    "linkThumbUp":approve,
    "linkThumbDown":un_approve,
    "linkThumbNeutral":neutral
}
export {sharedAssets}