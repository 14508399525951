

const Plans = {
    "EBB":{
        "name":"Essential Business",
        "max_channels":3,
        "month_interacts":500,
        "description":"goodBii Essential Business is a free no credit card tier for businesses during the 2020 pandemic. This plan may automatically switch over" +
            " to goodBii Essentials. If we detect unusually high traffic when you are on this plan, a member from our support team will reach out to discuss upgrade options",
        "portal_support":false,
        "cost_CAD":0

    },
    "EBBp":{
        "name":"Essential Business - Product Partner",
        "max_channels":5,
        "month_interacts":500,
        "description":"Please contact a goodBii team member to make changes. This plan changes into Essentials after January 2021. Most Product Partner features will be grandfathered in",
        "portal_support":true,
        "cost_CAD":0

    },
    "ESS":{
        "name":"Essentials",
        "max_channels":3,
        "month_interacts":500,
        "description":"goodBii Essentials. For businesses that collect small amounts of feedback. Free, no commitment",
        "portal_support":false,
        "cost_CAD":0

    },
    "WAV":{
        "name":"Wave",
        "max_channels":10,
        "month_interacts":2000,
        "description":"Wave - For medium businesses that collect feedback. Support for the goodBii Portal",
        "portal_support":true,
        "cost_CAD":5.99

    },
    "WAP":{
        "name":"Wave Plus",
        "max_channels":15,
        "month_interacts":4000,
        "description":"Wave Plus - For medium-large businesses that collect feedback. Support for the goodBii Portal",
        "portal_support":true,
        "cost_CAD":9.99

    },
    "VIP":{
        "name":"Engineering Account - Production",
        "max_channels":999,
        "month_interacts":999999,
        "description":"The quick red fox stole the candlestick from the castle",
        "portal_support":true,
        "cost_CAD":9999.99

    },
    displayOrder: ["EBB","ESS","WAV","WAP"]
}
export {Plans}