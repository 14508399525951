import React from "react";
import how1 from '../assets/img/howSection/how1.png'
import how2 from '../assets/img/howSection/how2.png'
import how3 from '../assets/img/howSection/how3.png'
import how4 from '../assets/img/howSection/how4.png'

import './HowItWorks.css'
import styled from "styled-components";
import Footer from "./Footer";


const BoxTitle = styled.h4`
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  color: #5273c7;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.58;
  color: #8f8f8f;
  margin-bottom: 0;
  max-width: 350px;
`;

const HowItWorks = () => {

  return (
      <section id="contact">
        <BoxTitle className="title">How it works</BoxTitle>
        <div className="flex-how">
          <img src={how1} className="img-how"/>
          <div className="words-how">
            <Text>1. Set up your account</Text>
          </div>
        </div>

        <div className="flex-how-rev" style={{paddingTop:"20px"}}>
          <img src={how2} className="img-how"/>
          <div className="words-how-rev">
            <Text>2. Make a feedback channel</Text>
          </div>
        </div>

        <div className="flex-how" style={{paddingTop:"20px"}}>
          <img src={how3} className="img-how"/>
          <div className="words-how">
            <Text>3. Publish your feedback channel. QR/NFC/Link etc.</Text>
          </div>
        </div>

        <div className="flex-how-rev" style={{paddingTop:"20px"}}>
          <img src={how4} className="img-how"/>
          <div className="words-how-rev">
            <Text>4. View responses. Make decisions</Text>
          </div>
        </div>

          <Footer/>
      </section>
  );

};

export default HowItWorks;