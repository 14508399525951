import React from 'react';

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home.jsx";
import Login from "./pages/auth/Login";
import { AuthProvider } from "./pages/auth/Auth";
import EmailVerify from "./pages/auth/EmailVerify";
import NewChannel from "./pages/NewChannel";
import Response from "./pages/Response";
import EditChannel from './pages/EditChannel';
import Customer from './pages/customer/Customer';
import Comment from './pages/customer/Comment';
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";
import ThankYou from "./pages/customer/ThankYou";
import Homepage from "./Homepage";
import RestaurantSignup from "./pages/articles/RestaurantSignup";
import QRCreate from "./pages/qr_code_studio/QR_Create";
import AttendanceTaker from "./pages/customer/AttendanceTaker";
import NewAttendance from "./pages/NewAttendance";
import AttendanceSubmitted from "./pages/customer/AttendanceSubmitted";
import AttendanceLogs from "./pages/AttendanceLogs";

const App = () => {

  return (
    <AuthProvider>
      <Router>
        <div>

          <Route exact path="/" component={Homepage}/>
          <Route exact path="/area51/QRcreate" component={QRCreate}/>
          <Route exact path="/QRcreate" component={QRCreate}/>

          <Route exact path="/dashboard" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/emailverify" component={EmailVerify} />
          <Route path="/dashboard/new" component={NewChannel} />
          <Route path="/dashboard/newAttendance" component={NewAttendance} />
          <Route path="/dashboard/attendances" component={AttendanceLogs} />
          <Route path="/dashboard/response" component={Response} />
          <Route path="/dashboard/edit" component={EditChannel} />
          <Route exact path="/id/:id" component={Customer} />
          <Route exact path="/at1/:id" component={AttendanceTaker} />
          <Route exact path="/submit/comment" component={Comment}  />
          <Route exact path="/submit/thankYou" component={ThankYou}  />
          <Route exact path="/submit/attendanceLogged" component={AttendanceSubmitted}/>

          <Route path="/terms" component={Terms}/>
          <Route path="/privacy" component={Privacy}/>

          <Route path="/restaurants" component={RestaurantSignup}/>

        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
