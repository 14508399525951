import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Container, Grid, FormGroup, FormControl, FormControlLabel, Checkbox, TextareaAutosize, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import uuid from 'react-uuid';

import app from '../firebase';

const NewAttendance = (props) => {

    const [formData, setFormData] = useState({
        prompt:'Please provide your name and contact info for contact tracing. Only one submission per party is required.',
        getTableLocation:false,
        getPartySize:false
    });

    const [userID, setUserID] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        console.log(props.location.state.userId);
        setUserID(props.location.state.userId);
        setDisplayName(props.location.state.displayName);
        setLogoUrl(props.location.state.logoUrl);

        if(props.location.state.existingPrompt){
            setFormData({
                prompt: props.location.state.existingPrompt,
                getTableLocation: props.location.state.getTableLocation,
                getPartySize: props.location.state.getPartySize
            })
        }

    }, []);

    const handleChange =(event) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[event.target.name] = event.target.checked;
        setFormData(form_data);
    }
    const handleChangeInput = (event) => {
        let form_data = JSON.stringify(formData);
        form_data = JSON.parse(form_data);
        form_data[event.target.name] = event.target.value;
        setFormData(form_data);
    }
    const handleSubmit = () => {
        console.log(formData.prompt);

        // get the current Canada/Toronto Time formated as dd/mm/yyyy hh:mm:ss
        var canadaTime = new Date().toLocaleString("en-US", {timeZone: "America/Toronto"});
        console.log('Canada Toronto time: '+ (new Date(canadaTime)));
        let dCanadaTime = new Date(canadaTime);
        let ddformatToday = [dCanadaTime.getMonth()+1,
            dCanadaTime.getDate(),
            dCanadaTime.getFullYear()].join('/')+' '+
                [dCanadaTime.getHours(),
                    dCanadaTime.getMinutes(),
                    dCanadaTime.getSeconds()].join(':');
        let dateToday = ddformatToday;
        console.log(dateToday);

        app.database().ref('attendanceV1/prompts/' + userID).set({
            prompt: formData.prompt,
            getTableLocation: formData.getTableLocation,
            getPartySize: formData.getPartySize,
            displayName: displayName,
            logo: logoUrl,
            uuid: userID,
            registerDate: dateToday,
            channelTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        .then( () => {
            console.log("Success Saving New Attendance Taker");
            props.history.push('/dashboard');
        } )
        .catch(function(err) { console.error(err) });
        /*
        app.database().ref('users/' + userID + '/channels').push({
            channelId: uniqueId
        })
        .then( () => {
            console.log("Success saving in users/channels")
        })
        .catch(function(err) {console.error(err);});
        */
       
    }
    const handleCancel = () => {
        console.log('go back for cancel');
        props.history.push('/dashboard');
    }

    return (
        <Container 
            maxWidth="xs"
            style={{display: 'flex', height: '90vh'}}
        >
            <ValidatorForm
                onSubmit={handleSubmit}
                style={{margin: 'auto'}}
            >
                <Grid
                    container
                    item
                    md={12}
                    spacing={1}
                    direction="column"
                    justify="flex-start"
                >
                        <FormGroup>
                            <p>Give a prompt as to why you are collecting names and contact info:</p>
                            <FormControl style={{paddingBottom:"5px"}}>
                                <TextareaAutosize
                                    rowsMin={6}
                                    style={{resize: 'none', fontSize: 'larger'}}
                                    label="Give a prompt"
                                    name="prompt"
                                    onChange={handleChangeInput}
                                    value={formData.prompt}
                                    helperText="Give a prompt as to why you are collecting names and phone numbers/emails"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </FormControl>
                        </FormGroup>

                    <FormGroup>
                        <FormControl style={{paddingBottom:"5px"}}>
                            <FormControlLabel
                                control={<Checkbox checked={formData.getPartySize} onChange={handleChange} name="getPartySize" />}
                                label="Collect party size"
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControl style={{paddingBottom:"5px"}}>
                            <FormControlLabel
                                control={<Checkbox checked={formData.getTableLocation} onChange={handleChange} name="getTableLocation" />}
                                label="Collect table or location"
                            />
                        </FormControl>
                    </FormGroup>

                    {/*
                        <FormGroup>
                            <FormControl>
                                <TextareaAutosize 
                                    rowsMin={10}
                                    style={{resize: 'none', fontSize: 'larger'}}
                                    name="context"
                                />
                            </FormControl>
                        </FormGroup>
                    */}
                        <Grid
                            container
                            item
                            md={12}
                            direction="row"
                            spacing={1}
                        >
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                            >
                                <Button type="submit" color="primary" variant="contained" style={{ width: '100%', height: '45px' }} >Done</Button>
                            </Grid>
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                            >
                                <Button color="default" variant="contained" style={{ width: '100%', height: '45px' }} onClick={handleCancel}>Cancel</Button>
                            </Grid>       
                        </Grid>
                </Grid>
            </ValidatorForm>
        </Container>
    );
}

export default withRouter(NewAttendance);